import classes from "./AccountHover.module.scss";
import { useContext } from "react";
import { AccountContext } from "../auth/Account";
import { useHistory } from "react-router-dom";

const AccountHover = (props) => {
  const history = useHistory();
  const { logout } = useContext(AccountContext);

  // const accountType = props.accountStatus === "active" ? "Pro" : "Free";
  const accountType = props.accountStatus === "active" ? "Pro" : "Pro";

  const billingDashboardHandler = () => {
    // if (props.subscription.canceled_at) {
    //   history.push("/pricing");
    //   return;
    // }

    if (props.subscription?.status === "active") {
      // history.push("/billing");
      props.openBillingModal("open");
    } else {
      history.push("/upgrade");
    }
  };

  const logoutHandler = () => {
    console.log("Logging Out...");
    logout();
  };

  console.log(props.subscription);

  return (
    <div className={classes.account}>
      <div className={classes.account__main}>
        <div className={classes.account__username}>{props.userName}</div>
        <div className={classes.account__type}>{accountType}</div>
      </div>
      <div className={classes.account__links}>
        {/* <div
          onClick={billingDashboardHandler}
          className={classes["account__links--cat"]}
        >
          Billing
        </div> */}
        {/* {props.subscription?.status !== "active" && (
          <div
            onClick={billingDashboardHandler}
            className={classes["account__links--cat"]}
          >
            Trial Days:{" "}
            <span className={classes.trialDaysNum}>{props.trialDays}</span>
          </div>
        )} */}

        <div onClick={logoutHandler} className={classes["account__links--cat"]}>
          Logout
        </div>
      </div>
    </div>
  );
};

export default AccountHover;
