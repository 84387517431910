import classes from "./PomodoroSwitch.module.scss";
import { useEffect, useState } from "react";
import useTextColor from "../hooks/use-theme-text";

const PomodoroSwitch = (props) => {
  const [pomodoroTimerSwitch, setPomodoroTimerSwitch] = useState(
    localStorage.getItem("isPomodoro") === "true" ? true : false
  ); // Toggle Switch

  useEffect(() => {
    if (localStorage.getItem("isPomodoro") === "true") {
      props.timerChangeHandler("pomo");
    }
  });

  // Toggle Switch Change handling
  const handleTimerChange = () => {
    if (!pomodoroTimerSwitch) {
      props.timerChangeHandler("pomo");
      localStorage.setItem("isPomodoro", "true");
    } else {
      props.timerChangeHandler("tromo");
      localStorage.setItem("isPomodoro", "false");
    }
    setPomodoroTimerSwitch(!pomodoroTimerSwitch);
  };

  const textColor = useTextColor();

  const style = {
    color: textColor,
  };

  return (
    <section className={classes.sectionTimerToggle}>
      <div className={classes.pomodoroOptions}>
        <div className={classes.optionsLabel} style={style}>
          Switch to Pomodoro Timer
        </div>
        <label className={classes.switch}>
          <input
            type="checkbox"
            checked={pomodoroTimerSwitch}
            onChange={handleTimerChange}
          />
          <span className={classes.slider}></span>
        </label>
      </div>
    </section>
  );
};

export default PomodoroSwitch;
