import React from "react";
import classes from "./PromoBanner.module.scss"; // Import the CSS module
import { Link } from "react-router-dom";

const PromoBanner = () => {
  return (
    <div className={classes.promoBanner}>
      <Link to="/app?unlock" className={classes.promoLink}>
        Unlock all features for only{" "}
        <span className={classes.oldPrice}>$129</span>{" "}
        <span className={classes.newPrice}>$67</span>{" "}
        <span className={classes.oneTime}>one-time purchase!</span>
      </Link>
    </div>
  );
};

export default PromoBanner;
