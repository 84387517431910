import BuyButton from "../../UI/BuyButton";
import classes from "./CallToActionSection.module.scss";

function CallToActionSection() {
  return (
    <div className={classes.callToActionSectionWrapper}>
      <div className={classes.callToActionSection}>
        <div className={classes.callToActionContent}>
          <h1 className={classes.callToActionContent__text}>
            Get your precious hours back. Try our app today
          </h1>
          <BuyButton></BuyButton>
        </div>
      </div>
    </div>
  );
}

export default CallToActionSection;
