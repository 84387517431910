import BuyButton from "../../UI/BuyButton";
import classes from "./SectionHomepage.module.scss";
import starIconImage from "./../../images/starIcon.png";

const reviews = [
  {
    rating: 5,
    text: `"I use Tromodoro every day."`,
  },
  {
    rating: 5,
    text: `"Best Productivity tool in the market"`,
  },
  {
    rating: 5,
    text: `"Great features and loved the inbuilt playlist."`,
  },
];

const SectionOne = (props) => {
  return (
    <section className={classes.section__homepage}>
      <div className={classes.starRatingReview}>
        {reviews.map((review, index) => (
          <div key={index} className={classes.review}>
            <div className={classes.stars}>
              {Array(review.rating)
                .fill()
                .map((_, i) => (
                  <img
                    key={i}
                    src={starIconImage}
                    alt="Star"
                    className={classes.star}
                  />
                ))}
            </div>
            <div className={classes.text}>{review.text}</div>
          </div>
        ))}
      </div>
      <div className={classes.headings}>
        <h1 className={classes.primaryHeading}>
          Working from home can feel good
        </h1>
        <h3 className={classes.secondaryHeading}>
          The Most Advanced Productivity Tool
        </h3>
      </div>

      <div className={classes.comparison_block}>
        {/* <span className={classes.comparison_block__heading}>
          After GetOData API:
        </span> */}
        Tromodoro helps you be more productive with it's advance timer, Inbuilt
        Music playlist, and features that make your work feel good.
      </div>
      <div className={classes.emailInput}>
        <BuyButton></BuyButton>
      </div>
    </section>
  );
};

export default SectionOne;
