import classes from "./Tasks.module.scss";
import IndividualTasks from "./IndividualTasks";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Status from "../../auth/Status";
import { useContext } from "react";
import { AccountContext } from "../../auth/Account";
import UserPool from "../../auth/UserPool";
import LifetimeOverlay from "../LifetimeOverlay/LifetimeOverlay";

// Steps:
// Get the selected Timer starting from first.
//
//
//
//

function extractUserId(jwtToken) {
  // extract the userId from the JWT token
  const parts = jwtToken.split(".");
  const payload = JSON.parse(atob(parts[1]));
  return payload.sub;
}

const Tasks = (props) => {
  const [tasksEntered, setTasksEntered] = useState([]);
  const [newTask, setNewTask] = useState("");

  const [taskType, setTaskType] = useState("Tromodoro");
  const [key, setKey] = useState(1);

  const status = Status();
  const history = useHistory();
  const loggedInStatus = status;
  const { getSession } = useContext(AccountContext);

  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const openOverlay = () => {
    setIsOverlayOpen(true);
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
  };

  // console.log(props.isWorkTimerEnded);

  // Fetch data only once when component is rendered
  useEffect(() => {
    if (!status) {
      // Get Local storage tasks for not logged in users
      const allTasks = localStorage.getItem("tasks")
        ? JSON.parse(localStorage.getItem("tasks"))
        : [];

      setTasksEntered(allTasks);
    } else if (status) {
      // Get dynamoDB tasks for logged in users
      // Getting session and jwt token
      getSession().then((session) => {
        const jwtToken = session.getIdToken().getJwtToken();

        // Get data function
        const getData = async (jwt) => {
          try {
            const resp = await fetch(
              `https://snjy271pdj.execute-api.us-east-1.amazonaws.com/dev/tromororo?userId=${extractUserId(
                jwt
              )}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: jwt,
                },
              }
            );

            const data = await resp.json();

            if (data.errorType) {
              // No tasks in the database
              setTasksEntered([]);
            } else {
              setTasksEntered(data);
            }

            return data;
          } catch {
            setTasksEntered([]);
          }
        };

        // Calling getdata function
        getData(jwtToken);
      });
    } else {
      setTasksEntered([]);
    }
  }, [status]);

  const newTaskHandler = (event) => {
    setNewTask(event.target.value);
  };

  // Task Addition on adding the task
  const [currSessionNum, setCurrSessionNum] = useState(0);
  const taskAdditionHandler = (event) => {
    // If user is not logged In, open the login Modal
    if (!loggedInStatus || props.planType !== "paid") {
      setIsOverlayOpen(true);
      return;
      // props.showProVersion();
      // history.push("/login");
    }

    // // If user is not subscribed, open the proVersionModal
    // if (props.subscriptionStatus !== "active" && !props.isTrialValid) {
    //   props.showProVersion();
    //   return;
    // }

    const copiedList = [...tasksEntered];
    let lastTaskId = tasksEntered.length;
    let status = "On Way";
    if (lastTaskId + 1 === 1) {
      status = "In Progress";
    }
    if (newTask.trim().length === 0) return;

    // New Task addition to the object
    copiedList.push({
      TaskId: `${lastTaskId + 1}`,
      TaskName: newTask,
      TaskDescription: "Test Description",
      TaskType: taskType,
      TaskNums: sessionNum,
      TaskCurrNums: "0",
      Status: status,
    });

    if (!status) {
      localStorage.setItem("tasks", JSON.stringify(copiedList));
    } else {
      // Getting session and jwt token
      getSession().then((session) => {
        const jwtToken = session.getIdToken().getJwtToken();

        fetch(
          "https://snjy271pdj.execute-api.us-east-1.amazonaws.com/dev/tromororo",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
            body: JSON.stringify({
              userId: extractUserId(jwtToken),
              tasks: copiedList,
            }),
          }
        )
          .then((response) => response.json())
          .then((data) => {
            // console.log(data)
          })
          .catch((err) => console.log(err));
      });
    }
    setTasksEntered(copiedList);
    setNewTask("");

    setTaskType(taskType === "Tromodoro" ? "ShortWork" : "Tromodoro");
  };

  // Task Manipulation after timer is completed

  let taskManipulated = localStorage.getItem("taskManipulated")
    ? localStorage.getItem("taskManipulated")
    : false;

  if (taskManipulated === "false") {
    taskManipulated = false;
  } else if (taskManipulated === "true") {
    taskManipulated = true;
  }

  // To be able to manipulate the tasks again
  // useEffect(() => {
  //   if (
  //     props.isWorkTimerEnded === "work-started" ||
  //     props.isWorkTimerEnded === "short-work-started"
  //   ) {
  //     setTaskManipulated(false);
  //   }
  // }, [props.isWorkTimerEnded]);

  // Finding the Top Selected Task which is not completed
  const topCurrTask = tasksEntered.find((task) => {
    const currMode = props.currMode;
    const taskType = task.TaskType;

    if (
      currMode === "work" &&
      taskType === "Tromodoro" &&
      +task.TaskCurrNums / +task.TaskNums !== 1
    ) {
      return task;
    } else if (
      currMode === "short-work" &&
      taskType === "ShortWork" &&
      +task.TaskCurrNums / +task.TaskNums !== 1
    ) {
      return task;
    } else {
      return null;
    }
  });

  useEffect(() => {
    const topCurrTaskID = topCurrTask?.TaskId;
    if (topCurrTask?.Selected && topCurrTask?.Status !== "In Progress") {
      // Changing Task Status to In Progress

      const copiedList = [...tasksEntered];

      // Getting index of selected Task
      const lastTaskInProgressIndex = copiedList
        .map((task) => task.TaskId)
        .lastIndexOf(topCurrTaskID);

      copiedList[lastTaskInProgressIndex].Status = "In Progress";

      // Final Task Addition
      if (!status) {
        localStorage.setItem("tasks", JSON.stringify(copiedList));
      } else {
        // Getting session and jwt token
        getSession().then((session) => {
          const jwtToken = session.getIdToken().getJwtToken();

          fetch(
            "https://snjy271pdj.execute-api.us-east-1.amazonaws.com/dev/tromororo",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: jwtToken,
              },
              body: JSON.stringify({
                userId: extractUserId(jwtToken),
                tasks: copiedList,
              }),
            }
          )
            .then((response) => response.json())
            .then((data) => {
              // console.log(data);
            })
            .catch((err) => console.log(err));
        });

        setTasksEntered(copiedList);
      }
    }
  }, [props.currMode]);

  const customTasksAddition = (finalList) => {
    if (!status) {
      localStorage.setItem("tasks", JSON.stringify(finalList));
    } else {
      // Getting session and jwt token
      getSession().then((session) => {
        const jwtToken = session.getIdToken().getJwtToken();

        fetch(
          "https://snjy271pdj.execute-api.us-east-1.amazonaws.com/dev/tromororo",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
            body: JSON.stringify({
              userId: extractUserId(jwtToken),
              tasks: finalList,
            }),
          }
        )
          .then((response) => response.json())
          .then
          // (data) => console.log(data)
          ()
          .catch((err) => console.log(err));
      });
    }
    setTasksEntered(finalList);
  };

  const mainRemoveTaskHandler = (data) => {
    const copiedList = [...tasksEntered];
    // Removing the task with taskID === data
    let finalList = [];
    if (copiedList.length > 0) {
      finalList = copiedList.filter((obj) => +obj.TaskId !== +data);
      finalList.map((obj, index) => {
        obj.TaskId = `${index + 1}`;
        return obj;
      });
    }

    // Custom tasks addition
    customTasksAddition(finalList);
  };

  const taskResetHandler = (data) => {
    const copiedList = [...tasksEntered];
    // Removing the task with taskID === data
    let finalList = [];
    if (copiedList.length > 0) {
      finalList = copiedList.map((obj) => {
        if (+obj.TaskId === +data) {
          obj.TaskCurrNums = "0";
          obj.Status = "On Way";
        }
        return obj;
      });
    }

    // Custom tasks addition
    customTasksAddition(finalList);
  };

  const TaskTypeHandler = (event) => {
    setTaskType(event.target.value);
  };

  const [sessionNum, setSessionNum] = useState("1");

  tasksEntered.map((task) => (task["Selected"] = false));

  if (topCurrTask) {
    tasksEntered[+topCurrTask.TaskId - 1]["Selected"] = true;
  }

  const TaskManipulation = () => {
    // if (!topCurrTask) {
    //   return;
    // }
    if (taskManipulated) return;
    if (
      props.isWorkTimerEnded === "work-ended" ||
      props.isWorkTimerEnded === "short-work-ended"
    ) {
      const copiedList = [...tasksEntered];
      // const lastTaskInProgressIndex = copiedList
      //   .map((task) => task.Status)
      //   .lastIndexOf("In Progress");

      // Getting index of selected Task
      const lastTaskInProgressIndex = copiedList
        .map((task) => task.Selected)
        .lastIndexOf(true);

      if (lastTaskInProgressIndex === -1) {
        let minutes;
        try {
          if (props.currMode === "work") {
            if (localStorage.getItem("timings")) {
              const latestTimings = localStorage.getItem("timings");
              const timingObject = JSON.parse(latestTimings);
              minutes = timingObject.work;
            } else {
              minutes = props.tromodoroNewTimings.work;
            }
          } else if (props.currMode === "short-work") {
            if (localStorage.getItem("timings")) {
              const latestTimings = localStorage.getItem("timings");
              const timingObject = JSON.parse(latestTimings);
              minutes = timingObject.shortWork;
            } else {
              minutes = props.tromodoroNewTimings.shortWork;
            }
          }

          // console.log("Timer was completed but no tasks were selected");
          localStorage.setItem("taskManipulated", true);
          getSession().then((session) => {
            const jwtToken = session.getIdToken().getJwtToken();

            fetch(
              "https://snjy271pdj.execute-api.us-east-1.amazonaws.com/dev/tromo-user-activities",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: jwtToken,
                },
                body: JSON.stringify({
                  userId: extractUserId(jwtToken),
                  taskName: "",
                  minutes: minutes,
                }),
              }
            )
              .then((response) => response.json())
              .then((data) => {
                // console.log(data);
              })
              .catch((err) => console.log(err));
          });

          return;
        } catch (error) {}

        return;
      }

      const lastCurrSessionsNum =
        copiedList[lastTaskInProgressIndex].TaskCurrNums;
      if (topCurrTask)
        copiedList[lastTaskInProgressIndex].TaskCurrNums = `${
          +lastCurrSessionsNum + 1
        }`;
      let taskName = topCurrTask.TaskName;
      let minutes;
      let taskNums = +topCurrTask.TaskNums;

      copiedList.forEach((task) => {
        if (task.Selected === true) {
          task.Status = "In Progress";
        }
        if (+task.TaskCurrNums / +task.TaskNums === 1 && topCurrTask) {
          task.Status = "Completed";

          if (topCurrTask.TaskType === "Tromodoro") {
            if (localStorage.getItem("timings")) {
              const latestTimings = localStorage.getItem("timings");
              const timingObject = JSON.parse(latestTimings);
              minutes = timingObject.work;
            } else {
              minutes = props.tromodoroNewTimings.work;
            }
          } else if (topCurrTask.TaskType === "ShortWork") {
            if (localStorage.getItem("timings")) {
              const latestTimings = localStorage.getItem("timings");
              const timingObject = JSON.parse(latestTimings);
              minutes = timingObject.shortWork;
            } else {
              minutes = props.tromodoroNewTimings.shortWork;
            }
          }

          // minutes = +minutes * taskNums;

          try {
            getSession().then((session) => {
              const jwtToken = session.getIdToken().getJwtToken();

              fetch(
                "https://snjy271pdj.execute-api.us-east-1.amazonaws.com/dev/tromo-user-activities",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: jwtToken,
                  },
                  body: JSON.stringify({
                    userId: extractUserId(jwtToken),
                    taskName: taskName,
                    minutes: minutes,
                  }),
                }
              )
                .then((response) => response.json())
                .then((data) => {
                  // console.log(data);
                })
                .catch((err) => console.log(err));
            });
          } catch (error) {}
        }
      });
      // copiedList[lastTaskInProgressIndex + 1].Status = "In Progress";

      // Final Task Addition
      if (!status) {
        localStorage.setItem("tasks", JSON.stringify(copiedList));
      } else {
        // Getting session and jwt token
        getSession().then((session) => {
          const jwtToken = session.getIdToken().getJwtToken();

          fetch(
            "https://snjy271pdj.execute-api.us-east-1.amazonaws.com/dev/tromororo",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: jwtToken,
              },
              body: JSON.stringify({
                userId: extractUserId(jwtToken),
                tasks: copiedList,
              }),
            }
          )
            .then((response) => response.json())
            .then((data) => {
              // console.log(data);
            })
            .catch((err) => console.log(err));
        });

        localStorage.setItem("taskManipulated", true);
        setTasksEntered(copiedList);
      }
    }
  };
  // Running the task Manipulation for check
  TaskManipulation();

  return (
    <section className={classes.tasks}>
      <div className={classes.tasks__heading}>TASKS</div>
      {/* Checking if tasksEntered exists, and if it exists then checking if time is tromo or other and showing tasks according to that */}
      {tasksEntered
        ? props.currTimer === "tromo"
          ? tasksEntered.map((task) => (
              <IndividualTasks
                key={task.TaskId}
                taskIndex={task.TaskId}
                currTimer={props.currTimer}
                currMode={props.currMode}
                selected={task.Selected}
                title={task.TaskName}
                status={task.Status}
                description={task.TaskDescription}
                taskType={task.TaskType}
                taskNums={task.TaskNums}
                taskCurrNums={task.TaskCurrNums}
                taskRemoval={mainRemoveTaskHandler}
                taskReseter={taskResetHandler}
              ></IndividualTasks>
            ))
          : tasksEntered
              .filter((task) => task.TaskType === "Tromodoro")
              .map((task) => (
                <IndividualTasks
                  key={task.TaskId}
                  taskIndex={task.TaskId}
                  currTimer={props.currTimer}
                  currMode={props.currMode}
                  selected={task.Selected}
                  title={task.TaskName}
                  status={task.Status}
                  description={task.TaskDescription}
                  taskType={task.TaskType}
                  taskNums={task.TaskNums}
                  taskCurrNums={task.TaskCurrNums}
                  taskRemoval={mainRemoveTaskHandler}
                  taskReseter={taskResetHandler}
                ></IndividualTasks>
              ))
        : null}

      <div className={classes.tasks__addition}>
        <input
          type="text"
          placeholder="What's next? Add tasks"
          className={classes["tasks__addition--input"]}
          onChange={newTaskHandler}
          value={newTask}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              taskAdditionHandler();
            }
          }}
        ></input>
        <div className={classes.tasks__addition__options}>
          {/* <label className={classes.optionsLabel}>Type:</label> */}
          <select
            onChange={TaskTypeHandler}
            className={classes.optionsControlOne}
            value={taskType}
            key={key}
          >
            <option key="1" value="Tromodoro">
              {props.currTimer === "tromo" ? "Tromodoro" : "Pomodoro"}
            </option>
            {props.currTimer === "tromo" && (
              <option key="2" value="ShortWork">
                Short Work
              </option>
            )}
          </select>
          {/* <label className={classes.optionsLabel}></label> */}
          <input
            type="number"
            placeholder="1"
            defaultValue="1"
            min="1"
            onChange={(event) => setSessionNum(event.target.value)}
            className={classes.optionsControlTwo}
          ></input>
        </div>
        <div onClick={taskAdditionHandler} className={classes.addTask}>
          Add Task
        </div>
        {isOverlayOpen && (
          <LifetimeOverlay
            email={props.email}
            userName={props.userName}
            onClose={closeOverlay}
          />
        )}
      </div>
    </section>
  );
};

export default Tasks;
