import classes from "./Theme.module.scss";

const Theme = (props) => {
  const ThemeChangeHandler = (event) => {
    localStorage.setItem("theme", event.target.value);
  };

  return (
    <div className={classes["settings__category--themes"]}>
      <h3 className={classes.category__heading}>Theme</h3>
      <div className={classes.category__themes}>
        <select
          onChange={ThemeChangeHandler}
          className={classes.optionsControlOne}
          defaultValue={
            localStorage.getItem("theme")
              ? localStorage.getItem("theme")
              : "dark"
          }
        >
          <option key="1" value="dark">
            Dark
          </option>
          <option key="2" value="beach">
            Beach
          </option>
          <option key="3" value="light">
            Light
          </option>
        </select>
      </div>
    </div>
  );
};

export default Theme;
