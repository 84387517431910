import classes from "./SectionPricing.module.scss";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Amplify } from "aws-amplify";
import { AmplifyDataObj } from "../../auth/AmplifyPool";
import TickIcon from "./../../images/tick.png";
import crossIcon from "./../../images/cross.png";
import mostPopularTag from "./../../images/most-popular-tag.png";
import { Link } from "react-router-dom";
import rightArrow from "./../../images/rightGreenArrow.png";

Amplify.configure(AmplifyDataObj);
Auth.configure(AmplifyDataObj);

function SectionPricing() {
  const [jwtToken, setJwtToken] = useState("");
  const [email, setEmail] = useState("");
  const [signedName, setSignedName] = useState("");
  const history = useHistory();

  // const [pricingYearly, setPricingYearly] = useState(false);

  // Authenticating the user Details
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();

        console.log("-----------User details-----------");
        console.log(user);

        user.getSession((err, session) => {
          // Here is the JWT Token for authorization
          setJwtToken(session.getIdToken().getJwtToken());
          console.log("-----------Session details-----------");
          console.log(session);
        });

        let { username, attributes } = user;
        let email = attributes?.email;
        if (email?.length === undefined) {
          email = user.getSignInUserSession().getIdToken().payload.email;
          username = user.getSignInUserSession().getIdToken().payload.name;
        }

        setEmail(email);
        setSignedName(username);
      } catch (error) {
        // Not Logged in
      }
    };

    fetchUserData();
  }, []);

  // const handlePriceYearly = () => {
  //   setPricingYearly(!pricingYearly);
  // };

  const starterPlanHandler = () => {
    if (jwtToken) {
      history.push(
        `https://getodata.lemonsqueezy.com/checkout/buy/d7528564-f29b-4764-b740-a7cd30231019?checkout[email]=${email}&checkout[name]=${signedName}`
      );
    } else {
      history.push("/register");
    }
  };

  const standardPlanHandler = () => {
    if (jwtToken) {
      history.push(
        `https://getodata.lemonsqueezy.com/checkout/buy/cbde7113-bc9c-41e8-b746-affe4e5e9cec?checkout[email]=${email}&checkout[name]=${signedName}`
      );
    } else {
      history.push("/register");
    }
  };

  const proPlanHandler = () => {
    if (jwtToken) {
      history.push(
        `https://getodata.lemonsqueezy.com/checkout/buy/d06a02f3-46da-4c34-bd9f-e471b2ee91d3?checkout[email]=${email}&checkout[name]=${signedName}`
      );
    } else {
      history.push("/register");
    }
  };

  return (
    <section className={classes["section-pricing"]}>
      <div className={classes.pricing}>
        <h1 className={classes.pricing__heading}>Simplified Pricing Option</h1>
        <div className={classes.pricing__categories}>
          <div
            className={`${classes.pricing__box} ${classes.scalewhenHovered}`}
          >
            {/* <img
              src={mostPopularTag}
              alt="Most popular tag"
              className={classes.mostPopularTag}
            ></img> */}
            <h3
              className={`${classes["pricing__box--heading"]} ${classes["pricing__box--planGreen"]}`}
            >
              Lifetime Deal
            </h3>
            <div className={classes["pricing__box--number"]}>
              <span className={classes["pricing__box--number__value"]}>
                $67
              </span>
              <span
                className={classes["pricing__box--number__value--original"]}
              >
                $129
              </span>
            </div>
            <h3 className={classes["pricing__box--secondaryHeading"]}>
              Lifetime Unlimited access to all the features for a single one
              time purchase.
            </h3>
            <ul className={classes.features}>
              <li className={classes.features__list}>
                <img
                  src={TickIcon}
                  alt="Tick Icon"
                  className={classes["features__list--icon"]}
                ></img>
                <div className={classes["features__list--text"]}>
                  Pomodoro & Tromodoro Timer
                </div>
              </li>
              <li className={classes.features__list}>
                <img
                  src={TickIcon}
                  alt="Tick Icon"
                  className={classes["features__list--icon"]}
                ></img>
                <div className={classes["features__list--text"]}>
                  Inbuilt Music Playlist
                </div>
              </li>
              <li className={classes.features__list}>
                <img
                  src={TickIcon}
                  alt="Tick Icon"
                  className={classes["features__list--icon"]}
                ></img>
                <div className={classes["features__list--text"]}>
                  Task Management
                </div>
              </li>
              <li className={classes.features__list}>
                <img
                  src={TickIcon}
                  alt="Tick Icon"
                  className={classes["features__list--icon"]}
                ></img>
                <div className={classes["features__list--text"]}>
                  Website Blocker
                </div>
              </li>
              <li className={classes.features__list}>
                <img
                  src={TickIcon}
                  alt="Tick Icon"
                  className={classes["features__list--icon"]}
                ></img>
                <div className={classes["features__list--text"]}>
                  Analytics Dashboard
                </div>
              </li>
              <li className={classes.features__list}>
                <img
                  src={TickIcon}
                  alt="Tick Icon"
                  className={classes["features__list--icon"]}
                ></img>
                <div className={classes["features__list--text"]}>
                  Access to all Templates
                </div>
              </li>

              <li className={classes.features__list}>
                <img
                  src={TickIcon}
                  alt="Tick Icon"
                  className={classes["features__list--icon"]}
                ></img>
                <div className={classes["features__list--text"]}>
                  + All upcoming features
                </div>
              </li>
            </ul>
            <a
              href="/app?unlock"
              onClick={standardPlanHandler}
              className={classes["pricing__box--btn"]}
            >
              <div className={classes.btnText}>Get Lifetime Deal</div>
              <span>
                <img
                  src={rightArrow}
                  className={classes.rightArrow}
                  alt="right arrow"
                ></img>
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionPricing;
