import classes from "./TaskOptionsHover.module.scss";

const TaskOptionsHover = (props) => {
  const deleteTaskHandler = () => {
    props.deleteTask();
  };

  const resetTaskHandler = () => {
    props.resetTask();
  };

  return (
    <div className={classes.account}>
      <div className={classes.account__main}>
        {/* <div className={classes.account__type}>Mark Complete</div> */}
        <div className={classes.account__type} onClick={deleteTaskHandler}>
          Delete
        </div>
        <div className={classes.account__type} onClick={resetTaskHandler}>
          Reset
        </div>
      </div>
    </div>
  );
};

export default TaskOptionsHover;
