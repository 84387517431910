import classes from "./Header.module.scss";
import logoImg from "./../images/tromodoro-logo.png";
import logoImgWhite from "./../images/tromodoro-logo-white.png";
import analyticsWhite from "./../images/analyticsWhite.png";
import analyticsDark from "./../images/analyticsWhite.png";
import userLogo from "./../images/profile-black.png";
import userLogoWhite from "./../images/profile-white.png";
import settingsIcon from "./../images/settings-black.png";
import settingsIconWhite from "./../images/settings-white.png";

import { Link } from "react-router-dom";
import Status from "../auth/Status";
import AccountInfo from "./AccountInfo";
import { useState, useEffect } from "react";
import useTextColor from "../hooks/use-theme-text";
import LifetimeDealButton from "../UI/LifetimeDealButton";

const Header = (props) => {
  const textColor = useTextColor();

  const style = {
    color: textColor,
  };

  return (
    <header className={classes.header}>
      <nav className={classes.navigation}>
        <div className={classes["main-logo"]}>
          <Link
            to="/"
            className={classes["navigation__link--text"]}
            style={style}
          >
            <img
              src={
                localStorage.getItem("theme") === "light"
                  ? logoImg
                  : logoImgWhite
              }
              alt="Swapnil Mane Logo"
              className={classes["main-logo__img"]}
            />
          </Link>
        </div>
        {/* For small Devices */}
        <ul className={classes.navListMobile}>
          <li
            onClick={props.onShowAnalytics}
            className={classes["navigation__item"]}
          >
            <div className={classes["navigation__link"]}>
              <img
                alt="Analytics Icon"
                src={
                  localStorage.getItem("theme") === "light"
                    ? analyticsDark
                    : analyticsWhite
                }
                className={classes["navigation__link--icon"]}
              ></img>
            </div>
          </li>

          <li
            onClick={props.onShowSettings}
            className={classes["navigation__item"]}
          >
            <div className={classes["navigation__link"]}>
              <img
                alt="Settings Icon"
                src={
                  localStorage.getItem("theme") === "light"
                    ? settingsIcon
                    : settingsIconWhite
                }
                className={classes["navigation__link--icon"]}
              ></img>
            </div>
          </li>

          {props.userName ? null : (
            <li className={classes["navigation__item"]}>
              <div className={classes["navigation__link"]}>
                <Link
                  to="/login"
                  className={classes["navigation__link--text"]}
                  style={style}
                >
                  <img
                    alt="Login Icon"
                    src={
                      localStorage.getItem("theme") === "light"
                        ? userLogo
                        : userLogoWhite
                    }
                    className={classes["account__link--icon"]}
                  ></img>
                </Link>
              </div>
            </li>
          )}

          {props.userName ? (
            <div className={classes.accountInfoComponent}>
              <AccountInfo
                userName={props.userName}
                accountStatus={props.accountStatus}
                isTrialValid={props.isTrialValid}
                trialDays={props.trialDays}
                subscription={props.subscription}
                openBillingModal={(data) => props.openBillingModal(data)}
              ></AccountInfo>
            </div>
          ) : null}
        </ul>
        {/*Standard Header  */}
        <ul className={classes["navigation__list"]}>
          <li
            onClick={props.onShowAnalytics}
            className={classes["navigation__item"]}
          >
            <div className={classes["navigation__link"]}>
              <img
                alt="onShowAnalytics Icon"
                src={
                  localStorage.getItem("theme") === "light"
                    ? analyticsDark
                    : analyticsWhite
                }
                className={classes["navigation__link--icon"]}
              ></img>
              <div style={style} className={classes["navigation__link--text"]}>
                Analytics
              </div>
            </div>
          </li>

          <li
            onClick={props.onShowSettings}
            className={classes["navigation__item"]}
          >
            <div className={classes["navigation__link"]}>
              <img
                alt="Settings Icon"
                src={
                  localStorage.getItem("theme") === "light"
                    ? settingsIcon
                    : settingsIconWhite
                }
                className={classes["navigation__link--icon"]}
              ></img>
              <div style={style} className={classes["navigation__link--text"]}>
                Settings
              </div>
            </div>
          </li>
          {/* Web Blocker */}
          <li
            onClick={props.onShowBlocker}
            className={classes["navigation__item"]}
          >
            <div className={classes["navigation__link"]}>
              <div style={style} className={classes["navigation__link--text"]}>
                Web Blocker
              </div>
            </div>
          </li>

          {/* {props.userName && (
            <li
              onClick={props.onShowAnalytics}
              className={classes["navigation__item"]}
            >
              <div className={classes["navigation__link"]}>
                <div
                  style={style}
                  className={classes["navigation__link--text"]}
                >
                  Analytics
                </div>
              </div>
            </li>
          )} */}
          {/* {props.userName &&
          (props.accountStatus === "free" ||
            props.accountStatus === "canceled" ||
            props.accountStatus === null) ? (
            <li className={classes["navigation__item"]}>
              <div className={classes["navigation__link"]}>
                <a
                  href="/upgrade"
                  className={classes["navigation__link--text"]}
                  style={style}
                >
                  Upgrade
                </a>
              </div>
            </li>
          ) : null} */}
          {/* {props.isSubscriptionDataLoaded &&
          props.accountStatus === "notLoggedIn" ? (
            <li className={classes["navigation__item"]}>
              <div className={classes["navigation__link"]}>
                <a
                  href="/pricing"
                  className={classes["navigation__link--text"]}
                  style={style}
                >
                  Pricing
                </a>
              </div>
            </li>
          ) : null} */}

          {props.userName ? null : (
            <li className={classes["navigation__item"]}>
              <div className={classes["navigation__link"]}>
                <Link
                  to="/signup"
                  className={classes["navigation__link--text"]}
                  style={style}
                >
                  Sign-up
                </Link>
              </div>
            </li>
          )}
          {props.userName ? null : (
            <li className={classes["navigation__item"]}>
              <div className={classes["navigation__link"]}>
                <Link
                  to="/login"
                  className={classes["navigation__link--text"]}
                  style={style}
                >
                  Login
                </Link>
              </div>
            </li>
          )}
          {props.planType !== "paid" && (
            <li className={classes["navigation__item"]}>
              <div className={classes["navigation__link"]}>
                <div
                  to="/login"
                  className={classes["navigation__link--text"]}
                  style={style}
                >
                  <LifetimeDealButton
                    userName={props.userName}
                    email={props.email}
                  ></LifetimeDealButton>
                </div>
              </div>
            </li>
          )}

          {props.userName ? (
            <div className={classes.accountInfoComponent}>
              <AccountInfo
                userName={props.userName}
                accountStatus={props.accountStatus}
                isTrialValid={props.isTrialValid}
                trialDays={props.trialDays}
                subscription={props.subscription}
                openBillingModal={(data) => props.openBillingModal(data)}
              ></AccountInfo>
            </div>
          ) : null}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
