import classes from "./BlockerBox.module.scss";
import infoIcon from "./../../images/info-icon-white.png";
import { useState, useEffect } from "react";
import { BlockExtensionId } from "../../topVars";
// import chrome from "chrome-extension-async";
import LifetimeOverlay from "../LifetimeOverlay/LifetimeOverlay";
import Status from "../../auth/Status";

const BlockerBox = (props) => {
  const [selectedOption, setSelectedOption] = useState(
    localStorage.getItem("blockOption")
      ? localStorage.getItem("blockOption")
      : "tromodoro"
  );

  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const openOverlay = () => {
    setIsOverlayOpen(true);
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
  };

  const [tromoBlockEnabled, setTromoBlockEnabled] = useState(false);
  const chrome = window?.chrome;

  useEffect(() => {
    if (props.blockerEnabled) {
      setTromoBlockEnabled(true);
    } else {
      setTromoBlockEnabled(false);
    }
  }, [props.blockerEnabled]);

  console.log(props.blockerEnabled);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    props.OptionChangedHandler(true);
    localStorage.setItem("blockOption", event.target.value);
  };
  const loggedInStatus = Status;

  const tromoBlockHandler = (event) => {
    if (!loggedInStatus || props.planType !== "paid") {
      setIsOverlayOpen(true);
      return;
      // props.showProVersion();
      // history.push("/login");
    }

    if (selectedOption === "never") {
      return;
    }
    if (!tromoBlockEnabled === true) {
      try {
        console.log(BlockExtensionId);
        chrome.runtime.sendMessage(BlockExtensionId, {
          startWork: true,
        });
        setTromoBlockEnabled(true);
      } catch (error) {}
    } else {
      try {
        chrome.runtime.sendMessage(BlockExtensionId, {
          stopWork: true,
        });
        setTromoBlockEnabled(false);
      } catch (error) {}
    }
    setTromoBlockEnabled(!tromoBlockEnabled);
  };

  return (
    <div className={classes.blockerBox}>
      <h2 className={classes.blockerBox__heading}>
        Web Blocker{" "}
        <span>
          <img
            src={infoIcon}
            alt="Info Icon"
            className={classes.infoIcon}
            onClick={props.onShowBlocker}
          ></img>
        </span>
      </h2>
      <div className={classes.blockConditions}>
        <label className={classes.blockConditions__text}>
          Block Websites during:
        </label>
        <select
          onChange={handleOptionChange}
          className={classes.optionsControl}
          value={selectedOption}
        >
          <option value="tromodoro" key="tromo">
            Tromodoro
          </option>
          <option value="tromoShortWork" key="tromoShortWork">
            Tromodoro & Short Work
          </option>
          <option value="shortWork" key="shortWork">
            Short Work
          </option>
          <option value="never" key="never">
            Never
          </option>
        </select>
      </div>
      <button
        onClick={tromoBlockHandler}
        className={
          tromoBlockEnabled === true
            ? `${classes.blockerBtn} ${classes.active}`
            : `${classes.blockerBtn}`
        }
      >
        {tromoBlockEnabled ? "Enabled" : "Disabled"}
      </button>
      {isOverlayOpen && (
        <LifetimeOverlay
          email={props.email}
          userName={props.userName}
          onClose={closeOverlay}
        />
      )}
    </div>
  );
};

export default BlockerBox;
