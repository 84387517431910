import useInput from "../hooks/use-input";
import classes from "./Verification.module.scss";
import { useLocation, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import MainLogo from "../UI/MainLogo";
import useThemeColorsWithBg from "../hooks/use-theme";
import useTextColor from "../hooks/use-theme-text";
import { Amplify } from "aws-amplify";
import { useContext } from "react";
import { AmplifyDataObj } from "../auth/AmplifyPool";
import { AccountContext } from "../auth/Account";

const Verification = (props) => {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const username = params.get("value");

  Amplify.configure(AmplifyDataObj);
  Auth.configure(AmplifyDataObj);
  const { handleAutoSignIn } = useContext(AccountContext);

  console.log(username);

  const {
    value: veriEntered,
    isValid: veriValid,
    hasError: veriInputInvalid,
    valueChangeHandler: veriHandler,
    inputBlurHandler: veriBlurHandler,
    reset: resetVeriValues,
  } = useInput((value) => value.trim() !== "" && value.length === 6);

  let formIsValid = false;

  if (veriValid) {
    formIsValid = true;
  }

  const submitFormHandler = async (event) => {
    event.preventDefault();

    if (!veriValid) {
      return;
    }

    try {
      await Auth.confirmSignUp(username, veriEntered);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      await handleAutoSignIn();

      history.push("/app");
      // window.location.reload();
    } catch (error) {
      console.log("error confirming sign up", error);
    }

    resetVeriValues();
  };

  const { textColor, bgColor } = useThemeColorsWithBg();

  const style = {
    color: textColor,
    backgroundColor: bgColor,
    zIndex: "200",
  };

  const textColorTwo = useTextColor();

  const styleText = {
    color: textColorTwo,
  };

  return (
    <section
      style={{ ...style, position: "absolute" }}
      className={classes.sectionVerification}
    >
      <MainLogo></MainLogo>
      <div style={style} className={classes["veri-page"]}>
        <h1 className={classes["veri-header"]}>Enter Verification Code</h1>
        <form
          style={style}
          onSubmit={submitFormHandler}
          className={classes["veri-form"]}
        >
          <div style={styleText} className={classes["control-group"]}>
            <label htmlFor="verification">Code received on your mail: </label>
            <input
              onChange={veriHandler}
              onBlur={veriBlurHandler}
              value={veriEntered}
              type="text"
              id="verification"
              placeholder="Verification code*"
            ></input>
            {veriInputInvalid && (
              <p className={classes["error-text"]}>Entered Code Not Valid!</p>
            )}
          </div>
          <button
            disabled={!formIsValid}
            className={classes["veri-btn"]}
            type="submit"
          >
            Create
          </button>
        </form>
      </div>
    </section>
  );
};

export default Verification;
