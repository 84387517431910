// import { Route, Switch, Redirect } from "react-router-dom";
import Header from "./components/Header";
import classes from "./App.module.scss";
import TimerBox from "./components/Timer/TimerBox";
import Info from "./components/Info/Info";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Settings from "./components/Settings/Settings";
import "react-toastify/dist/ReactToastify.css";
import { Route, Switch, Redirect } from "react-router-dom";
import Login from "./pages/Login";
import Signup from "./pages/SignUp";
import Verification from "./pages/Verification";
import { Account } from "./auth/Account";
import Tasks from "./components/Tasks/Tasks";
import MainPlayerWrapper from "./components/WebPlayer/MainPlayerWrapper";
// import { SpotifyGetAccessAndRefreshToken } from "./topVars";
// import Pricing from "./pages/Pricing";
import SpotifyLogin from "./components/WebPlayer/SpotifyLogin";
// import PaymentForm from "./pages/PaymentForm";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
import { Auth } from "aws-amplify";
import beachBg from "./images/beach/beach-bg.png";
import beachBgMedium from "./images/beach/beach-bg-medium.png";
import { Oval } from "react-loader-spinner";
import { Amplify } from "aws-amplify";
import { AmplifyDataObj } from "./auth/AmplifyPool";
import Billing from "./pages/Billing";
// import { STRIPE_PUBLISHABLE_KEY } from "./topVars";
import PomodoroSwitch from "./components/PomodoroSwitch";
import "./index.scss";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Footer from "./components/Footer";
// import Status from "./auth/Status";
import ReactGA from "react-ga";
// import Upgrade from "./pages/Upgrade";
import { useHistory } from "react-router-dom";
import ProVersion from "./components/ProVersion";
import BlockerModal from "./components/Blocker/BlockerModal";
import BlogMain from "./components/Blogs/BlogMain";
import AnalyticsModal from "./components/Analytics/AnalyticsModal";
import BlockerBox from "./components/Blocker/BlockerBox";
import HomepageSecondSection from "./components/HomepageSecond/HomepageSecondSection";
import HomepageThirdSection from "./components/HomepageThird/HomepageThirdSection";
import SectionOne from "./components/HomepageOne/SectionHomepage";
import HeaderHomepage from "./components/HeaderHomepage";
import SectionPricing from "./components/SectionPricing/SectionPricing";
import WallOfLove from "./components/WallOfLove/WallOfLove";
// import SectionTestimonials from "./components/SectionTestimonial/SectionTestimonials";
import CallToActionSection from "./components/CallToActionSection/CallToActionSection";
import PromoBanner from "./components/PromoBanner";
import { createAPIUser } from "./topVars";
import LifetimeOverlay from "./components/LifetimeOverlay/LifetimeOverlay";

const TRACKING_ID = "UA-179239758-2";
ReactGA.initialize(TRACKING_ID);

// const stripePromise = loadStripe(
//   process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY
// );

// const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

function App(props) {
  Amplify.configure(AmplifyDataObj);
  Auth.configure(AmplifyDataObj);
  const [accessToken, setAccessToken] = useState();
  // const [accessToken, setAccessToken] = useState(
  //   localStorage.getItem("accessToken")
  // );
  // const [premiumNotAvailable, setPremiumNotAvailable] = useState(false);
  const [code, setCode] = useState(localStorage.getItem("code"));
  const history = useHistory();

  const [jwtToken, setJwtToken] = useState("");
  // Google Analytics
  useEffect(() => {
    ReactGA.pageview("/");
  }, []);

  const [signedName, setSignedName] = useState("");
  const [email, setEmail] = useState("");

  // const [isTrialValid, setIsTrialValid] = useState("");
  // const [trialDays, setTrialDays] = useState("");
  const [planType, setPlanType] = useState("");
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has("unlock")) {
      openOverlay();
    }
  }, [location]);

  const openOverlay = () => {
    setIsOverlayOpen(true);
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
  };

  useEffect(() => {
    const fetchAPI = async () => {
      try {
        // Replace 'YOUR_API_ENDPOINT' with the actual endpoint of your AWS API Gateway
        const response = await fetch(
          `${createAPIUser}?access_token=${accessToken}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        console.log(data);

        setPlanType(data.PlanType);

        return data;
      } catch (error) {
        console.error("Error fetching the API:", error);
      }
    };

    if (jwtToken && accessToken) {
      fetchAPI();
    }
  }, [jwtToken, accessToken]);

  console.log("----------Here is the plan type--------------");
  console.log(planType);

  // Check and add trial
  // useEffect(() => {

  // const checkTrial = async () => {
  //   try {
  //     const response = await fetch(
  //       `https://snjy271pdj.execute-api.us-east-1.amazonaws.com/dev/start-trial`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: jwtToken,
  //         },
  //         body: JSON.stringify({
  //           userName: signedName,
  //           email: email,
  //         }),
  //       }
  //     );
  //     const data = await response.json();
  //     if (data?.User) {
  //       const trialStartDate = new Date(data.User.signupDate.S);

  //       let trialEndDate = "";
  //       try {
  //         trialEndDate = new Date(
  //           trialStartDate.getTime() + -1 * 24 * 60 * 60 * 1000
  //         ); // 30 days in milliseconds
  //       } catch (error) {
  //         console.log(error);
  //       }

  //       const currentDate = new Date();
  //       if (currentDate < trialEndDate) {
  //         const daysLeft = Math.ceil(
  //           (trialEndDate - currentDate) / (24 * 60 * 60 * 1000)
  //         );
  //         setIsTrialValid(true);
  //         setTrialDays(daysLeft);
  //       } else {
  //         setIsTrialValid(false);
  //       }
  //     } else {
  //       window.location.reload();
  //     }
  //   } catch (error) {
  //     // console.error(error);
  //   }
  // };

  // if (jwtToken) {
  //   checkTrial();
  // }
  // }, [jwtToken, email, signedName]);

  // For crisp
  const [signature, setSignature] = useState(null);

  useEffect(() => {
    const getSignature = async () => {
      try {
        const response = await fetch(
          `https://snjy271pdj.execute-api.us-east-1.amazonaws.com/dev/tromororo/crispchat`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: email,
            }),
          }
        );

        const data = await response.json();

        setSignature(data.signature);
        return data.signature;
      } catch (error) {
        // console.log("Error retrieving User", error.message);
      }
    };

    if (email) {
      getSignature();
    }
  }, [email]);

  useEffect(() => {
    if (signature && email) {
      window.$crisp.push(["set", "user:email", [email, signature]]);
    }
  }, [signature, email]);

  // For subscription ID of user
  // const [subscriptionId, setSubscriptionId] = useState("");

  // Check for subscription status
  // const [subscription, setSubscription] = useState("");
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);

  // For checking if pricing Page is needed or not
  const [subscriptionDataLoaded, setSubscriptionDataLoaded] = useState(false);

  // Get Email and username
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        let { username, attributes } = user;

        user.getSession((err, session) => {
          // Here is the JWT Token for authorization
          setJwtToken(session.getIdToken().getJwtToken());
          setAccessToken(session.accessToken.jwtToken);
        });

        let email = attributes?.email;
        if (email?.length === undefined) {
          email = user.getSignInUserSession().getIdToken().payload.email;
          username = user.getSignInUserSession().getIdToken().payload.name;
        }

        setEmail(email);
        setSignedName(username);
      })
      .catch(() => {
        setSubscriptionStatus("notLoggedIn");
        setSubscriptionDataLoaded(true);
      });

    // const getSubscriptionId = async () => {
    //   try {
    //     const response = await fetch(
    //       `https://snjy271pdj.execute-api.us-east-1.amazonaws.com/dev/payments/getuser?userId=${email}`,
    //       {
    //         method: "GET",
    //         headers: {
    //           "Content-Type": "application/json",
    //           Authorization: jwtToken,
    //         },
    //       }
    //     );

    //     const data = await response.json();
    //     setSubscriptionId(data.subscriptionId);
    //     if (!data.subscriptionId) {
    //       setSubscriptionStatus("free");
    //       setSubscriptionDataLoaded(true);
    //     }
    //     return subscriptionId;
    //   } catch (error) {
    //     // console.log("Error retrieving User", error.message);
    //   }
    // };
    // if (email) {
    //   getSubscriptionId();
    // }

    // const checkSubscriptionStatus = async () => {
    //   try {
    //     setSubscription("loading");
    //     const response = await fetch(
    //       "https://snjy271pdj.execute-api.us-east-1.amazonaws.com/dev/payments/retrievesubscription",
    //       {
    //         method: "POST",
    //         headers: {
    //           "Content-Type": "application/json",
    //           Authorization: jwtToken,
    //         },
    //         body: JSON.stringify({
    //           subscriptionId: subscriptionId,
    //         }),
    //       }
    //     );

    //     const data = await response.json();
    //     setSubscription(data.subscription);

    //     if (data.subscriptionStatus) {
    //       setSubscriptionStatus(data.subscriptionStatus);
    //       setSubscriptionDataLoaded(true);
    //     } else {
    //       setSubscriptionStatus("free");
    //       setSubscriptionDataLoaded(true);
    //     }
    //     return data;
    //   } catch (error) {
    //     setSubscriptionStatus("free");
    //     // console.log("Error retrieving subscription:", error.message);
    //   }
    // };
    // if (subscriptionId) {
    //   checkSubscriptionStatus();
    // }
  }, [email, jwtToken]);

  useEffect(() => {
    try {
      if (Notification.permission !== "granted") {
        Notification.requestPermission();
      }
    } catch {
      // Do nothing
    }
  }, []);

  const [infoModalIsShown, setInfoModalIsShown] = useState(false);
  const [settingsModalIsShown, setSettingsModalIsShown] = useState(false);
  const [blockerModalIsShown, setBlockerModalIsShown] = useState(false);
  const [analyticsModalIsShown, setAnalyticsModalIsShown] = useState(false);

  const showInfoModalHandler = () => {
    setInfoModalIsShown(true);
  };

  const showSettingsModalHandler = () => {
    setSettingsModalIsShown(true);
  };

  const showBlockerModalHandler = () => {
    setBlockerModalIsShown(true);
  };

  const showAnalyticsModalHandler = () => {
    setAnalyticsModalIsShown(true);
  };

  const hideInfoModalHandler = () => {
    setInfoModalIsShown(false);
  };

  const hideSettingsModalHandler = () => {
    setSettingsModalIsShown(false);
  };

  const hideBlockerModalHandler = () => {
    setBlockerModalIsShown(false);
  };

  const hideAnalyticsModalHandler = () => {
    setAnalyticsModalIsShown(false);
  };

  useEffect(() => {
    const getCode = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const codeValue = urlParams.get("code");
      // Storing the code in Local storage
      localStorage.setItem("code", codeValue);
      setCode(codeValue);
      if (codeValue) {
        history.push("/");
      }
    };
    getCode();
  }, [code, history]);

  // useEffect(() => {
  //   const getAccessToken = async () => {
  //     try {
  //       const response = await fetch(
  //         `${SpotifyGetAccessAndRefreshToken}?code=${code}`,
  //         {
  //           method: "GET",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: jwtToken,
  //           },
  //         }
  //       );
  //       const data = await response.json();
  //       const currAccessToken = data.accessToken;
  //       // Different access token

  //       // const refreshToken = data.refreshToken;
  //       // if (
  //       //   typeof currAccessToken !== "object" &&
  //       //   typeof refreshToken !== "object"
  //       // ) {
  //       //   // Storing the accessToken in Local storage
  //       //   if (currAccessToken) {
  //       //     localStorage.setItem("accessToken", currAccessToken);
  //       //     localStorage.setItem("refreshToken", refreshToken);
  //       //     setAccessToken(currAccessToken);
  //       //   }
  //       // }
  //       return currAccessToken;
  //     } catch (error) {
  //       // console.error(error);
  //     }
  //   };

  //   if (code) {
  //     getAccessToken();
  //   }
  // }, [code, jwtToken]);

  // Run Notifications
  const [ifWorkTimerEnded, setIfWorkTimerEnded] = useState(null);

  const notify = (data) => {
    if (data.type === "work-ended") {
      setIfWorkTimerEnded("work-ended");
      if (Notification.permission === "granted") {
        new Notification(
          `${currTimer === "tromo" ? "Tromodoro" : "Pomodoro"} has been Ended`,
          {
            body: `${
              currTimer === "tromo"
                ? "Lets move to the short work! 🚀🔥"
                : "Lets take a break! 😊"
            }`,
          }
        );
      }
    } else if (data.type === "short-work-ended") {
      setIfWorkTimerEnded("short-work-ended");
      if (Notification.permission === "granted") {
        new Notification("Short Work has been Ended", {
          body: "Lets take a break!😊",
        });
      }
    } else if (data.type === "break-ended") {
      setIfWorkTimerEnded("break-ended");
      if (Notification.permission === "granted") {
        new Notification("Break has been Ended", {
          body: `${
            currTimer === "tromo"
              ? "Lets start another Tromodoro Session! 🚀🔥"
              : "Lets start another Pomodoro Session! 🚀🔥"
          }`,
        });
      }
    } else {
      setIfWorkTimerEnded(data.type);
    }
  };

  const [tromodoroTimings, setTromodoroTimings] = useState({
    work: 45,
    shortWork: 15,
    break: 10,
  });

  // const ThemeChangeHandler = (data) => {
  //   // Handling theme changes
  //   console.log(data);
  // };

  // Play and pause spotify player through timer
  const [playerPlaying, setPlayerPlaying] = useState(false);
  const spotifyPlayerHandler = (data) => {
    if (data === "play") {
      setPlayerPlaying(true);
    } else if (data === "pause") {
      setPlayerPlaying(false);
    }
  };

  // For loading the player settings
  const [isPlayerReady, setPlayerReady] = useState(false);
  const playerReadyHandler = (data) => {
    setPlayerReady(data);
  };

  const [playSongsWhileTimer, setPlaySongsWhileTimer] = useState(true); //toggle switch
  const switchToggleChangeHandler = (data) => {
    setPlaySongsWhileTimer(data);
  };

  // const [currentTrack, setCurrentTrack] = useState(null);
  // const trackChangeHandler = (data) => {
  //   setCurrentTrack(data);
  // };

  const [currMode, setCurrMode] = useState("work");
  const modeGettinghandler = (mode) => {
    setCurrMode(mode);
  };

  // Switch Timer betn Pomodoro & Tromodoro
  const [currTimer, setCurrTimer] = useState("tromo");
  const changeTimerHandler = (data) => {
    setCurrTimer(data);
  };

  let bgColor = "#242424";
  if (localStorage.getItem("theme") === "light") {
    bgColor = "#F9F9F9";
  }

  const styleWithoutBgImage = {
    backgroundColor: bgColor,
    // backgroundImage: `url(${beachBg})`,
    // zIndex: -10
    // filter: "brightness(50%)",
    position: "relative",
    backgroundBlendMode: "screen",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    // height: "180vh",
  };

  const styleWithBgImage = {
    backgroundColor: bgColor,
    // backgroundImage: `url(${beachBg})`,
    backgroundImage: `url(${beachBg}), url(${beachBgMedium})`,
    // zIndex: -10
    // filter: "brightness(50%)",
    position: "relative",
    backgroundBlendMode: "screen",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    // height: "180vh",

    // specify image sources and sizes
    "@media (max-width: 600px)": {
      backgroundImage: `url(${beachBgMedium})`,
    },
  };

  const style =
    localStorage.getItem("theme") === "beach"
      ? styleWithBgImage
      : styleWithoutBgImage;

  // const [style, setStyle] = useState(styleWithBgImage);

  const TimingChangeHandler = (data) => {
    setTromodoroTimings(data);
  };

  const [isBillingModalVisible, setIsBillingModalVisible] = useState(false);

  const billingModalOpenHandler = (data) => {
    if (data === "open") {
      setIsBillingModalVisible(true);
    }
  };

  const hideBillingModalHandler = (data) => {
    setIsBillingModalVisible(false);
  };

  const [deviceId, setDeviceId] = useState(null);
  const deviceIdHandler = (data) => {
    setDeviceId(data);
  };

  // Inbuilt playlist Handler
  const [isInbuiltPlaylistSelected, setIsInbuiltPlaylistSelected] =
    useState(false);
  const inbuiltPlaylistSelectedOrNot = (data) => {
    setIsInbuiltPlaylistSelected(data);
  };

  const [isProVersionModal, setIsProVersionModal] = useState(false);
  const hideProVersionModalHandler = () => {
    setIsProVersionModal(false);
  };

  const showProVersionModalHandler = () => {
    setIsProVersionModal(true);
  };

  const logoutSpotifyHandler = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    // setPremiumNotAvailable(true);
  };

  const [blockerEnabled, setBlockerEnabled] = useState(false);
  const enableBlockerHandler = () => {
    if (planType !== "paid") {
      return;
    }

    setBlockerEnabled(true);
  };

  const disableBlockerHandler = () => {
    if (planType !== "paid") {
      return;
    }

    setBlockerEnabled(false);
  };

  const [optionChanged, setOptionChanged] = useState(false);
  const OptionChangedHandler = (data) => {
    setOptionChanged(data);
  };

  return (
    <Account>
      <Switch>
        <Route path="/home" exact>
          <Redirect to="/"></Redirect>
        </Route>
        <Route path="/login">
          <Login></Login>
        </Route>
        <Route path="/signup">
          <Signup></Signup>
        </Route>
        {/* <Route path="/pricing">
          <Pricing></Pricing>
        </Route> */}
        {/* <Route path="/upgrade">
          <Upgrade></Upgrade>
        </Route> */}
        {/* <Route path="/payment-form">
          <Elements stripe={stripePromise}>
            <PaymentForm></PaymentForm>
          </Elements>
        </Route> */}
        {/* <Route path="/billing">
          <Elements stripe={stripePromise}>
            <Billing></Billing>
          </Elements>
        </Route> */}
        <Route path="/verify">
          <Verification></Verification>
        </Route>
        {/* Add the below pages */}
        {/* We require your registered company address and domestic telephone number in India to be listed on the "Contact Us" or "Support" page on your website. */}
        <Route path="/support">
          <Verification></Verification>
        </Route>
        {/* Your website must have a Terms of Service (or Terms of Use) page, including distinct sections referring to your refund and cancellation policies. */}
        <Route path="/terms-of-service">
          <Terms></Terms>
        </Route>
        <Route path="/blog">
          <BlogMain></BlogMain>
        </Route>
        {/* Your website must have a Privacy Policy. It must describe the information collected, its purpose of use, to whom and how to disclose it and reasonable security practices followed to safeguard such information. */}
        <Route path="/privacy-policy">
          <Privacy></Privacy>
        </Route>
        <Route path="/privacy-policy">
          <Privacy></Privacy>
        </Route>
        <Route path="/app">
          <div className="homepage" style={style}>
            {/* <div className={classes.overlay}></div> */}
            <div className={classes.topContent}>
              {subscriptionStatus !== "loading" ? (
                <Header
                  onShowCart={showInfoModalHandler}
                  onShowSettings={showSettingsModalHandler}
                  onShowBlocker={showBlockerModalHandler}
                  onShowAnalytics={showAnalyticsModalHandler}
                  userName={signedName}
                  email={email}
                  planType={planType}
                  accountStatus={subscriptionStatus}
                  // isTrialValid={isTrialValid}
                  // trialDays={trialDays}
                  isSubscriptionDataLoaded={subscriptionDataLoaded}
                  // subscriptionId={subscriptionId}
                  // subscription={subscription}
                  openBillingModal={billingModalOpenHandler}
                  // currStyle={style}
                ></Header>
              ) : (
                <Oval
                  height={20}
                  width={20}
                  color="#fff"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#4fa94d"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              )}

              <main>
                {isBillingModalVisible && (
                  <Billing
                    hideBillingModalHandler={hideBillingModalHandler}
                  ></Billing>
                )}
                {isProVersionModal && (
                  <ProVersion
                    onHideProVersionModal={hideProVersionModalHandler}
                  ></ProVersion>
                )}
                <PomodoroSwitch
                  timerChangeHandler={changeTimerHandler}
                  // currStyle={style}
                ></PomodoroSwitch>
                <TimerBox
                  currTimer={currTimer}
                  playSpotify={spotifyPlayerHandler}
                  tromodoroNewTimings={tromodoroTimings}
                  notificationHandler={notify}
                  getMode={modeGettinghandler}
                  planType={planType}
                  enableBlocker={enableBlockerHandler}
                  blockerEnabled={blockerEnabled}
                  disableBlockerHandler={disableBlockerHandler}
                  optionChanged={optionChanged}
                  // currStyle={style}
                ></TimerBox>

                <Tasks
                  currTimer={currTimer}
                  currMode={currMode}
                  isWorkTimerEnded={ifWorkTimerEnded}
                  // subscriptionStatus={subscription?.status}
                  email={email}
                  userName={signedName}
                  planType={planType}
                  tromodoroNewTimings={tromodoroTimings}
                  // isTrialValid={isTrialValid}
                  showProVersion={showProVersionModalHandler}
                ></Tasks>
                {email && (
                  <MainPlayerWrapper
                    settingPlayerReady={playerReadyHandler}
                    // setNewAccess={(data) => setAccessToken(data)}
                    playSongsWhileTimer={playSongsWhileTimer}
                    isPlayerPlaying={playerPlaying}
                    getDeviceId={deviceIdHandler}
                    isInbuiltPlaylistSelected={isInbuiltPlaylistSelected}
                    // currStyle={style}
                    // trackGotChanged={trackChangeHandler}
                  ></MainPlayerWrapper>
                )}
                <BlockerBox
                  onShowBlocker={showBlockerModalHandler}
                  blockerEnabled={blockerEnabled}
                  email={email}
                  userName={signedName}
                  planType={planType}
                  disableBlockerHandler={disableBlockerHandler}
                  OptionChangedHandler={OptionChangedHandler}
                ></BlockerBox>
                <SpotifyLogin
                  playerPlaying={playerPlaying}
                  token={accessToken}
                  refresh={localStorage.getItem("refreshToken")}
                  setNewAccess={(data) => setAccessToken(data)}
                  isPlayerReady={isPlayerReady}
                  logoutSpotify={logoutSpotifyHandler}
                  email={email}
                  userName={signedName}
                  planType={planType}
                  handleToggleSwitch={switchToggleChangeHandler}
                  isInbuiltPlaylistSelected={inbuiltPlaylistSelectedOrNot}
                  passDeviceId={deviceId}
                  // subscriptionStatus={subscription?.status}
                  // isTrialValid={isTrialValid}
                  showProVersion={showProVersionModalHandler}
                  // currentTrack={currentTrack}
                ></SpotifyLogin>

                {/* {user && accessToken && (
              <SpotifyPlayer
                isPlayerPlaying={playerPlaying}
                token={accessToken}
                refresh={localStorage.getItem("refreshToken")}
                setNewAccess={(data) => setAccessToken(data)}
              ></SpotifyPlayer>
            )} */}

                {infoModalIsShown && (
                  <Info onHideCart={hideInfoModalHandler}></Info>
                )}
                {settingsModalIsShown && (
                  <Settings
                    getTimings={TimingChangeHandler}
                    onHideSettingModal={hideSettingsModalHandler}
                  ></Settings>
                )}

                {blockerModalIsShown && (
                  <BlockerModal
                    onHideBlockerModal={hideBlockerModalHandler}
                  ></BlockerModal>
                )}
                {analyticsModalIsShown && (
                  <AnalyticsModal
                    onHideAnalyticsModal={hideAnalyticsModalHandler}
                  ></AnalyticsModal>
                )}
              </main>
            </div>
            {isOverlayOpen && (
              <LifetimeOverlay
                email={email}
                userName={signedName}
                onClose={closeOverlay}
              />
            )}
          </div>
        </Route>
        <Route path="/:param1?/:param2?" exact>
          <div className="homepage" style={style}>
            <div className={classes.topContent}>
              <PromoBanner></PromoBanner>
              {subscriptionStatus !== "loading" ? (
                <HeaderHomepage
                  onShowCart={showInfoModalHandler}
                  onShowSettings={showSettingsModalHandler}
                  onShowBlocker={showBlockerModalHandler}
                  onShowAnalytics={showAnalyticsModalHandler}
                  userName={signedName}
                  accountStatus={subscriptionStatus}
                  // isTrialValid={isTrialValid}
                  // trialDays={trialDays}
                  isSubscriptionDataLoaded={subscriptionDataLoaded}
                  // subscriptionId={subscriptionId}
                  // subscription={subscription}
                  openBillingModal={billingModalOpenHandler}
                  // currStyle={style}
                ></HeaderHomepage>
              ) : (
                <Oval
                  height={20}
                  width={20}
                  color="#fff"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#4fa94d"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              )}
            </div>
          </div>
          <SectionOne></SectionOne>
          <HomepageSecondSection></HomepageSecondSection>
          <SectionPricing></SectionPricing>
          {/* <SectionTestimonials></SectionTestimonials> */}
          <HomepageThirdSection></HomepageThirdSection>
          <WallOfLove></WallOfLove>
          <CallToActionSection></CallToActionSection>
          <Footer></Footer>
        </Route>
        {/* Your website must have a Cancellation & Refund Policy. You can also submit Terms Of Service URL, if it has the Cancellation & Refund Policy mentioned abiding Indian laws. */}
        {/* <Route path="/cancellation">
          <Verification></Verification>
        </Route> */}
      </Switch>
    </Account>
  );
}

export default App;
