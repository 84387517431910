import classes from "./Mode.module.scss";
import { useState, useEffect } from "react";
import useTextColor from "../../hooks/use-theme-text";

const Mode = (props) => {
  const textColor = useTextColor();

  const style = {
    color: textColor,
  };

  return (
    <li
      style={style}
      onClick={props.afterClick}
      className={
        props.modeEntered === props.currCategory
          ? `${classes.timerBox__item} ${classes.active}`
          : `${classes.timerBox__item}  ${classes.inactive}`
      }
    >
      {props.catText}
    </li>
  );
};

export default Mode;
