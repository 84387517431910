import classes from "./Settings.module.scss";
import Modal from "../../UI/Modal";
import settingsIcon from "./../../images/settings.png";
import TimeConfigure from "./TimeConfigure";
import Button from "./../../UI/Button";
import Theme from "./Theme";

const Settings = (props) => {
  let TromodoroTimings = { work: 45, shortWork: 15, break: 10 };

  if (localStorage.getItem("timings")) {
    TromodoroTimings = JSON.parse(localStorage.getItem("timings"));
  }

  const getDataHandler = (data) => {
    console.log(data);

    if (data.type === "work") {
      TromodoroTimings.work = data.time;
    } else if (data.type === "short-work") {
      TromodoroTimings.shortWork = data.time;
    } else if (data.type === "break") {
      TromodoroTimings.break = data.time;
    }
  };

  const applyButonHandler = () => {
    props.getTimings(TromodoroTimings);
    props.onHideSettingModal();

    // Saving the changed timings in local storage
    localStorage.setItem("timings", JSON.stringify(TromodoroTimings));

    window.location.reload();
  };

  return (
    <Modal
      hideCartFunction={props.onHideSettingModal}
      className={classes.settings}
    >
      <div className={classes.settings__info}>
        <h2 className={classes.settings__title}>Settings</h2>
        <img
          alt="Settings icon"
          src={settingsIcon}
          className={classes.settings__img}
          onClick={props.onHideSettingModal}
        ></img>
      </div>
      <TimeConfigure
        tromodoroTimings={TromodoroTimings}
        dataGetter={getDataHandler}
      ></TimeConfigure>
      <Theme></Theme>

      <div onClick={applyButonHandler} className={classes.apply}>
        <Button className={classes.apply__btn} text="Apply"></Button>
      </div>
    </Modal>
  );
};

export default Settings;
