import MainLogo from "../UI/MainLogo";
import classes from "./Billing.module.scss";
import { Auth } from "aws-amplify";
import { useState, useEffect } from "react";
import UpdatePlanModal from "./UpdatePlanModal";
import { Oval } from "react-loader-spinner";
import Modal from "../UI/Modal";
import exitIcon from "./../images/exit.png";

const Billing = (props) => {
  const [signedName, setSignedName] = useState("");
  const [renewalDate, setRenewalDate] = useState(null);
  const [email, setEmail] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState("");

  const [priceId, setPriceId] = useState("");
  const [cardType, setCardType] = useState(null);
  const [expiryMonth, setExpiryMonth] = useState(null);
  const [expiryYear, setExpiryYear] = useState(null);
  const [lastFour, setLastFour] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const [jwtToken, setJwtToken] = useState("");

  const hideBillingModal = () => {
    props.hideBillingModalHandler("close");
  };

  // Get Email and username
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        let { username, attributes } = user;
        user.getSession((err, session) => {
          // Here is the JWT Token for authorization
          setJwtToken(session.getIdToken().getJwtToken());
        });

        let email = attributes?.email;
        if (email?.length === undefined) {
          email = user.getSignInUserSession().getIdToken().payload.email;
          username = user.getSignInUserSession().getIdToken().payload.name;
        }

        setEmail(email);
        setSignedName(username);
      })
      .catch((error) => console.log(error));
  }, []);

  // Get the subscription ID of user
  const [subscriptionId, setSubscriptionId] = useState("");
  useEffect(() => {
    const getSubscriptionId = async () => {
      try {
        const response = await fetch(
          `https://snjy271pdj.execute-api.us-east-1.amazonaws.com/dev/payments/getuser?userId=${email}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          }
        );

        const data = await response.json();

        setSubscriptionId(data.subscriptionId);
        setCustomerId(data.customerId);
        return subscriptionId;
      } catch (error) {
        console.log("Error retrieving User", error.message);
      }
    };

    if (email) {
      getSubscriptionId();
    }
  }, [email, jwtToken, subscriptionId]);

  // Check for subscription status
  const [subscription, setSubscription] = useState("");
  const [subscriptionStatus, setSubscriptionStatus] = useState("");
  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      try {
        const response = await fetch(
          "https://snjy271pdj.execute-api.us-east-1.amazonaws.com/dev/payments/retrievesubscription",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
            body: JSON.stringify({
              subscriptionId: subscriptionId,
            }),
          }
        );

        const data = await response.json();
        setSubscriptionStatus(data.subscriptionStatus);
        setSubscription(data.subscription);
        setDefaultPaymentMethod(data.subscription.default_payment_method);
        setPriceId(data.subscription.plan.id);
        const renewDate = new Date(data.subscription.current_period_end * 1000); // convert seconds to milliseconds
        const options = { month: "long", day: "numeric", year: "numeric" };
        const formattedDate = renewDate.toLocaleDateString("en-US", options); // "March 27, 2023"
        setRenewalDate(formattedDate);

        return data;
      } catch (error) {
        console.log("Error retrieving subscription:", error.message);
      }
    };
    if (subscriptionId) {
      checkSubscriptionStatus();
    }
  }, [subscriptionId]);

  // Get Default Payment Method data

  useEffect(() => {
    const retrievePaymentMethod = async () => {
      try {
        const response = await fetch(
          "https://snjy271pdj.execute-api.us-east-1.amazonaws.com/dev/payments/retrievepaymentmethods",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
            body: JSON.stringify({
              customerId: customerId,
              defaultPaymentMethod: defaultPaymentMethod,
            }),
          }
        );

        const data = await response.json();
        console.log(data);

        setCardType(data.cardType);
        setExpiryMonth(data.expiryMonth);
        setExpiryYear(data.expiryYear);
        setLastFour(data.lastFour);
      } catch (err) {
        console.log(err);
      }
    };
    if (customerId) {
      retrievePaymentMethod();
    }
  }, [customerId, defaultPaymentMethod, jwtToken]);

  let planPrice = "";
  let currentPlan = "Free";
  let nextPaymentPrice = "";
  if (subscription) {
    planPrice = subscription.plan.amount / 100;
    nextPaymentPrice = planPrice;
    const couponDiscount = subscription?.discount?.coupon?.percent_off;
    const couponValidity = subscription?.discount?.coupon?.valid;
    if (couponValidity) {
      const discountAmount = planPrice * (couponDiscount / 100);
      nextPaymentPrice = planPrice - discountAmount;
    }

    currentPlan = "Tromodoro Pro";
  }

  const updatePlanHandler = () => {
    setShowUpdateModal(true);
  };

  const hideModalHandler = () => {
    setShowUpdateModal(false);
    window.location.reload(false);
  };

  const changeCardHandler = () => {};

  // If Plan is cancelled:
  let cancelDate;
  if (subscription.cancel_at_period_end) {
    let cancelDateInSeconds = subscription.cancel_at;

    const renewDate = new Date(cancelDateInSeconds * 1000); // convert seconds to milliseconds
    const options = { month: "long", day: "numeric", year: "numeric" };
    cancelDate = renewDate.toLocaleDateString("en-US", options); // "March 27, 2023"
  }

  let currencyType = "$";
  if (subscription.currency === "inr") {
    currencyType = "INR ";
  }

  console.log(subscription);

  return (
    <section className={classes.sectionBilling}>
      <Modal className={classes.billing}>
        <img
          alt="Settings icon"
          src={exitIcon}
          className={classes.exit__img}
          onClick={hideBillingModal}
        ></img>
        <div className={classes.billing__current}>
          <h1 className={classes["billing__current--headingPrimary"]}>
            Billing
          </h1>
          <h3 className={classes["billing__current--headingSecondary"]}>
            Manage your plan and Billing details.
          </h3>
          <div className={classes.Cards}>
            <div className={classes.billingCard}>
              <div className={classes.billingCard__heading}>Current Plan</div>

              {planPrice ? (
                <div className={classes.billingCard__charge}>
                  {currencyType}
                  {planPrice}
                  <span className={classes.billingFrequency}>
                    {" "}
                    {subscription.plan.interval === "year"
                      ? "/ year"
                      : "/ month"}
                  </span>
                </div>
              ) : (
                <Oval
                  height={20}
                  width={20}
                  color="#fff"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#4fa94d"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              )}

              <div className={classes.billingCard__plan}>Tromodoro Pro</div>
              {subscription.cancel_at_period_end && (
                <div className={classes.billingCard__cancelsAt}>
                  cancels at {cancelDate}
                </div>
              )}

              <button
                className={classes["billing--btn"]}
                onClick={updatePlanHandler}
              >
                Update Plan
              </button>
            </div>
            {!subscription.cancel_at_period_end && (
              <div
                className={`${classes.billingCard} ${classes.billingCard__nextPayment}`}
              >
                <div
                  className={`${classes.billingCard__heading} ${classes["billingCard__heading--nextPayment"]}`}
                >
                  Next Payment
                </div>
                {planPrice ? (
                  <div className={classes.billingCard__charge}>
                    {currencyType}
                    {nextPaymentPrice}
                  </div>
                ) : (
                  <Oval
                    height={20}
                    width={20}
                    color="#fff"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                )}
                {renewalDate ? (
                  <div className={classes.billingCard__plan}>
                    {renewalDate.toLocaleString()}
                  </div>
                ) : (
                  <Oval
                    height={20}
                    width={20}
                    color="#fff"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                )}
              </div>
            )}
          </div>
        </div>

        <div className={classes.billing__method}>
          <h1 className={classes["billing__method--headingPrimary"]}>
            Payment Method
          </h1>

          <div className={`${classes.billingMethod}`}>
            {cardType ? (
              <div className={`${classes.billingMethod__type}`}>{cardType}</div>
            ) : (
              <Oval
                height={20}
                width={20}
                color="#fff"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#4fa94d"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            )}

            {expiryMonth ? (
              <div className={classes.billingMethod__expiry}>
                {expiryMonth}/{expiryYear}
              </div>
            ) : (
              <Oval
                height={20}
                width={20}
                color="#fff"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#4fa94d"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            )}
            {lastFour ? (
              <div className={classes.billingMethod__details}>
                **** **** **** {lastFour}
              </div>
            ) : (
              <Oval
                height={20}
                width={20}
                color="#fff"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#4fa94d"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            )}

            <button
              className={classes["card--btn"]}
              onClick={changeCardHandler}
            >
              Change
            </button>
          </div>
        </div>
      </Modal>
      {showUpdateModal && (
        <UpdatePlanModal
          subscriptionId={subscriptionId}
          jwtToken={jwtToken}
          subscription={subscription}
          hideModal={hideModalHandler}
        ></UpdatePlanModal>
      )}
    </section>
  );
};

export default Billing;
