import React from "react";
import TickIcon from "./../../images/tick.png"; // Ensure this path is correct
import styles from "./LifetimeOverlay.module.scss";

const LifetimeOverlay = ({ onClose, userName, email }) => {
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className={styles.overlay} onClick={handleOverlayClick}>
      <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        <div className={styles.modalHeader}>
          <h2>Unlock all features</h2>
          <p className={styles.price}>
            $67 <span className={styles.oldPrice}>$129</span>
          </p>
          <p className={styles.oneTimePayment}>One time payment!</p>
        </div>
        <div className={styles.modalBody}>
          <h3>What's included</h3>
          <ul className={styles.features}>
            <li className={styles.featureItem}>
              <img
                src={TickIcon}
                alt="Tick Icon"
                className={styles.featureIcon}
              />
              Pomodoro Timer
            </li>

            <li className={styles.featureItem}>
              <img
                src={TickIcon}
                alt="Tick Icon"
                className={styles.featureIcon}
              />
              Inbuilt Music Playlist
            </li>
            <li className={styles.featureItem}>
              <img
                src={TickIcon}
                alt="Tick Icon"
                className={styles.featureIcon}
              />
              Task Management
            </li>
            <li className={styles.featureItem}>
              <img
                src={TickIcon}
                alt="Tick Icon"
                className={styles.featureIcon}
              />
              Tromodoro Timer
            </li>
            <li className={styles.featureItem}>
              <img
                src={TickIcon}
                alt="Tick Icon"
                className={styles.featureIcon}
              />
              Website Blocker
            </li>
            <li className={styles.featureItem}>
              <img
                src={TickIcon}
                alt="Tick Icon"
                className={styles.featureIcon}
              />
              Analytics Dashboard
            </li>
            <li className={styles.featureItem}>
              <img
                src={TickIcon}
                alt="Tick Icon"
                className={styles.featureIcon}
              />
              Access to all Themes
            </li>

            <li className={styles.featureItem}>
              <img
                src={TickIcon}
                alt="Tick Icon"
                className={styles.featureIcon}
              />
              +All upcoming features!
            </li>
          </ul>
        </div>
        <div className={styles.modalFooter}>
          {/* <p className={styles.priceFooter}>$67 One time payment.</p> */}
          {userName ? (
            <a
              href={`https://tromodoro.lemonsqueezy.com/buy/6cdf908c-37a1-4b73-8841-382521248b95?checkout[email]=${email}&checkout[name]=${userName}`}
              target="_blank"
              rel="noreferrer"
              className={styles.ctaButton}
            >
              Get it now!
            </a>
          ) : (
            <a href="/signup" className={styles.ctaButton}>
              Sign-up/Login to get deal
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default LifetimeOverlay;
