import classes from "./AccountInfo.module.scss";
import userLogo from "./../images/profile-black.png";
import userLogoWhite from "./../images/profile-white.png";
import AccountHover from "./AccountHover";
import { useState } from "react";

const AccountInfo = (props) => {
  const [accountInfoClicked, setAccountInfoClicked] = useState(false);

  return (
    <div className={classes.accountInfo}>
      <div
        onClick={() => setAccountInfoClicked(!accountInfoClicked)}
        className={classes["accountInfo__expand"]}
      >
        {/* {user.getUsername().substring(0, 1).toUpperCase() +
                      user.getUsername().substring(1)} */}
        <img
          alt="Account Info Logo"
          src={
            localStorage.getItem("theme") === "light" ? userLogo : userLogoWhite
          }
          className={classes.profileImg}
        ></img>
      </div>
      {accountInfoClicked ? (
        <AccountHover
          userName={props.userName}
          accountStatus={props.accountStatus}
          isTrialValid={props.isTrialValid}
          trialDays={props.trialDays}
          subscription={props.subscription}
          openBillingModal={(data) => props.openBillingModal(data)}
        ></AccountHover>
      ) : null}
    </div>
  );
};

export default AccountInfo;
