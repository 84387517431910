import MainLogo from "../../UI/MainLogo";
import classes from "./BlogMain.module.scss";
import Footer from "../Footer";
import blogFeaturedPage from "./../../images/blogFeaturedPage.png";
import { Fragment } from "react";

const BlogMain = (props) => {
  const blogOpenHandler = () => {};

  return (
    <Fragment>
      <section className={classes.blogMain}>
        <MainLogo></MainLogo>
        <div className={classes.blogSection}>
          <h1 className={classes.blogHeading}>Tromo Blogs</h1>
          <div onClick={blogOpenHandler} className={classes["blog-list"]}>
            <div key="sas" className={classes["blog-item"]}>
              <img
                className={classes.blogImage}
                src={blogFeaturedPage}
                alt="Blog"
              ></img>
              <div className={classes.blogContent}>
                <h2 className={classes.blogContent__heading}>
                  5 Web Apps that will make you Highly Productivity!
                </h2>
                <p className={classes.blogContent__desc}>
                  Apps that can help you achieve your goals, minimize your
                  stress, and be on the path to greater success.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </Fragment>
  );
};

export default BlogMain;
