import useInput from "../hooks/use-input";
import classes from "./Login.module.scss";
import { useState, useContext } from "react";
import { AccountContext } from "../auth/Account";
import { useHistory } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import { AmplifyDataObj } from "../auth/AmplifyPool";
import MainLogo from "../UI/MainLogo";
import logoBg from "./../images/logo-bg.png";
import GoogleIcon from "./../images/google-icon.png";
import { ToastContainer, toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import "react-toastify/dist/ReactToastify.css";
import useThemeColorsWithBg from "../hooks/use-theme";
import useTextColor from "../hooks/use-theme-text";
// import rightArrow from "./../images/rightGreenArrow.png";

const Login = (props) => {
  // Always keep the below code in component, since it cause redirection
  Amplify.configure(AmplifyDataObj);
  Auth.configure(AmplifyDataObj);
  const [loading, setLoading] = useState(false);

  const { authenticate } = useContext(AccountContext);
  const history = useHistory();

  const {
    value: emailEntered,
    isValid: emailValid,
    hasError: emailInputInvalid,
    valueChangeHandler: emailHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmailValues,
  } = useInput((value) => value.trim() !== "" && value.includes("@"));

  const {
    value: passEntered,
    isValid: passValid,
    hasError: passInputInvalid,
    valueChangeHandler: passHandler,
    inputBlurHandler: passBlurHandler,
    reset: resetPassValues,
  } = useInput((value) => value.trim() !== "");

  let formIsValid = false;

  if (emailValid && passValid) {
    formIsValid = true;
  }

  const submitFormHandler = (event) => {
    event.preventDefault();

    if (!emailValid || !passValid) {
      return;
    }

    setLoading(true);

    authenticate(emailEntered, passEntered)
      .then((data) => {
        setLoading(false);
        console.log(data);
        if (data.includes("Error")) {
          toast.error(`${data}`, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.log("Failed to Log in", err);
        setLoading(false);
      });

    resetEmailValues();
    resetPassValues();
  };

  const submitGoogleSignIn = (event) => {
    event.preventDefault();
    console.log(" Google Login Redirect");

    Auth.federatedSignIn({ provider: "Google" });
  };

  const { textColor, bgColor } = useThemeColorsWithBg();

  const style = {
    color: textColor,
    backgroundColor: bgColor,
    zIndex: "200",
  };

  const textColorTwo = useTextColor();

  const styleText = {
    color: textColorTwo,
  };

  return (
    <section
      style={{ ...style, position: "absolute" }}
      className={classes["section-login"]}
    >
      <MainLogo></MainLogo>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <img alt="Logo Bg" src={logoBg} className={classes.logoBg}></img>
      <div style={style} className={classes["login-page"]}>
        <h1 className={classes["login-header"]}>Welcome Back</h1>
        <h3 style={styleText} className={classes["login-secondaryHeader"]}>
          No account?
          <a href="/signup" className={classes["login-signUpLink"]}>
            {" "}
            Sign Up{" "}
          </a>
        </h3>
        {loading ? (
          <h3 className={classes.loadingIcon} style={styleText}>
            <Oval
              height={20}
              width={20}
              color="#fff"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#4fa94d"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </h3>
        ) : (
          <div>
            <div className={classes["googleSignIn"]}>
              <button
                onClick={submitGoogleSignIn}
                className={classes["googleSignIn__btn"]}
              >
                <img
                  src={GoogleIcon}
                  alt="Google Sign in Icon"
                  className={classes.googleIcon}
                ></img>
                <div className={classes["googleSignIn__btn--text"]}>
                  Sign in with Google
                </div>
              </button>
            </div>
            <div className={classes["or"]}>Or</div>
            <form
              onSubmit={submitFormHandler}
              className={classes["login-form"]}
            >
              <div className={classes["control-group"]}>
                <label style={styleText} htmlFor="email">
                  Email address
                </label>
                <input
                  onChange={emailHandler}
                  onBlur={emailBlurHandler}
                  value={emailEntered}
                  placeholder="Email address*"
                  type="email"
                  id="email"
                ></input>
                {emailInputInvalid && (
                  <p className={classes["error-text"]}>
                    Entered Email Not Valid!
                  </p>
                )}
              </div>
              <div className={classes["control-group"]}>
                <label style={styleText} htmlFor="password">
                  Password
                </label>
                <input
                  onChange={passHandler}
                  onBlur={passBlurHandler}
                  value={passEntered}
                  placeholder="Password*"
                  type="password"
                  id="password"
                ></input>
                {passInputInvalid && (
                  <p className={classes["error-text"]}>
                    Entered Password Not Valid!
                  </p>
                )}
              </div>
              <button
                disabled={!formIsValid}
                className={classes["sign-in-btn"]}
                type="submit"
              >
                Sign in
              </button>
            </form>
          </div>
        )}
      </div>
    </section>
  );
};

export default Login;
