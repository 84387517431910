import { CognitoUserPool } from "amazon-cognito-identity-js";

const poolData = {
  UserPoolId: "us-east-1_9qkqcWoBv",
  ClientId: "7at0532epiohsf61t2q5lq555q",
};

// Test Data:
// const poolData = {
//   UserPoolId: "us-east-1_KyHfk0Xmw",
//   ClientId: "3oer5o0r9akb7ie55ebenpcc32",
// };

export default new CognitoUserPool(poolData);
