import classes from "./SpotifyLogin.module.scss";
import SpotifyLogo from "./../../images/spotifyLogo.png";
import Status from "../../auth/Status";
import { SpotifyLoginURL } from "../../topVars";
import SpotifyPlayer from "./SpotifyPlayer";
import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import nightInKyotoCover from "./../../music/nightInKyotoCover.png";
import airplaneCover from "./../../music/airplaneCover.jpg";
import amongCloudsCover from "./../../music/among-the-clouds-cover.jpg";
import beyondHorizonCover from "./../../music/BeyondHorizonCover.jpg";
import rainCover from "./../../music/BeyondHorizonCover.jpg";
import useThemeColorsWithBg from "../../hooks/use-theme";
import useTextColor from "../../hooks/use-theme-text";
import LifetimeOverlay from "../LifetimeOverlay/LifetimeOverlay";
import nightMusic from "./../../music/night-in-kyoto-avbe-main-version-21302-01-57.mp3";
import airplaneMusic from "./../../music/airplane-mode-yokonap-main-version-21460-03-58.mp3";
import amongtheCloudsMusic from "./../../music/among-the-clouds-haquin-main-version-17088-03-34.mp3";
import beyondTheHorizonMusic from "./../../music/beyond-the-horizon-yasumu-main-version-22465-02-05.mp3";
import rainAmbienceSoftMusic from "./../../music/rain-ambience-soft-fascinatedsound-1-02-04.mp3";

const SpotifyLogin = (props) => {
  const status = Status();
  const history = useHistory();
  const [inBuiltPlaylistPlaying, setInBuiltPlaylistPlaying] = useState(false);
  const [isInbuiltPlaylistSelected, setIsInbuiltPlaylistSelected] = useState(
    localStorage.getItem("isInbuiltPlaylistSelected") === "true" ? true : false
  );
  const [selectedSong, setSelectedSong] = useState(
    localStorage.getItem("selectedSong")
      ? localStorage.getItem("selectedSong")
      : null
  );

  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const openOverlay = () => {
    setIsOverlayOpen(true);
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
  };

  const audioRef = useRef(null);

  const playAudio = () => {
    audioRef.current.play();
  };

  const pauseAudio = () => {
    audioRef.current.pause();
  };

  const [playSongsWhileTimer, setPlaySongsWhileTimer] = useState(
    localStorage.getItem("isPlaySongWhileTimerToggleOn") === "true"
      ? true
      : false
  ); // Toggle Switch

  useEffect(() => {
    if (playSongsWhileTimer === false) {
      props.handleToggleSwitch(false);
    }
  });

  useEffect(() => {
    if (isInbuiltPlaylistSelected) {
      props.isInbuiltPlaylistSelected(true);
    }
  });
  const loggedInStatus = Status;

  // Toggle Switch Change handling
  const handlePlayingSongs = () => {
    if (!loggedInStatus || props.planType !== "paid") {
      setIsOverlayOpen(true);
      return;
      // props.showProVersion();
      // history.push("/login");
    }

    props.handleToggleSwitch(!playSongsWhileTimer);
    localStorage.setItem(
      "isPlaySongWhileTimerToggleOn",
      `${!playSongsWhileTimer}`
    );
    setPlaySongsWhileTimer(!playSongsWhileTimer);
  };

  // const toggleSongSwitchHandler = (data) => {
  //   props.handleToggleSwitch(data);
  // };

  useEffect(() => {
    if (
      props.playerPlaying &&
      playSongsWhileTimer &&
      isInbuiltPlaylistSelected
    ) {
      if (selectedSong === "night") {
        if (!`${audioRef.current.src}`.includes(nightMusic)) {
          audioRef.current.src = nightMusic;
        }
        audioRef.current.play();
        setInBuiltPlaylistPlaying(true);
      } else if (selectedSong === "airplane") {
        if (!`${audioRef.current.src}`.includes(airplaneMusic)) {
          audioRef.current.src = airplaneMusic;
        }
        audioRef.current.play();
        setInBuiltPlaylistPlaying(true);
      } else if (selectedSong === "amongClouds") {
        if (!`${audioRef.current.src}`.includes(amongtheCloudsMusic)) {
          audioRef.current.src = amongtheCloudsMusic;
        }
        audioRef.current.play();
        setInBuiltPlaylistPlaying(true);
      } else if (selectedSong === "beyondHorizon") {
        if (!`${audioRef.current.src}`.includes(beyondTheHorizonMusic)) {
          audioRef.current.src = beyondTheHorizonMusic;
        }
        audioRef.current.play();
        setInBuiltPlaylistPlaying(true);
      } else if (selectedSong === "rainAmbience") {
        if (!`${audioRef.current.src}`.includes(rainAmbienceSoftMusic)) {
          audioRef.current.src = rainAmbienceSoftMusic;
        }
        audioRef.current.play();
        setInBuiltPlaylistPlaying(true);
      }
    } else {
      audioRef.current.pause();
      setInBuiltPlaylistPlaying(false);
    }
  }, [
    props.playerPlaying,
    playSongsWhileTimer,
    isInbuiltPlaylistSelected,
    selectedSong,
  ]);

  const handlePlayPauseOfInbuiltPlaylist = (song) => {
    if (!loggedInStatus || props.planType !== "paid") {
      setIsOverlayOpen(true);
      return;
      // props.showProVersion();
      // history.push("/login");
    }

    if (selectedSong === song) {
      props.isInbuiltPlaylistSelected(!isInbuiltPlaylistSelected);
      localStorage.setItem(
        "isInbuiltPlaylistSelected",
        !isInbuiltPlaylistSelected
      );
      setIsInbuiltPlaylistSelected(!isInbuiltPlaylistSelected);
      // setInBuiltPlaylistPlaying(!inBuiltPlaylistPlaying);
    } else {
      // When new song is selected

      setSelectedSong(song);
      localStorage.setItem("selectedSong", song);
      if (!isInbuiltPlaylistSelected) {
        props.isInbuiltPlaylistSelected(!isInbuiltPlaylistSelected);
        localStorage.setItem(
          "isInbuiltPlaylistSelected",
          !isInbuiltPlaylistSelected
        );
        setIsInbuiltPlaylistSelected(!isInbuiltPlaylistSelected);
      }
    }
  };

  const spotifyLoginHandler = async () => {
    if (status) {
      const resp = await fetch(SpotifyLoginURL);

      const spotifyLoginURL = await resp.json();
      window.location.replace(spotifyLoginURL);
      // window.location.reload();
    } else {
      history.push("/login");
    }
  };

  // Play and pause spotify player through timer
  // const [accessToken, setAccessToken] = useState(
  //   localStorage.getItem("accessToken")
  // );
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    localStorage.getItem(accessToken);
  }, [accessToken]);

  const [playerPlaying, setPlayerPlaying] = useState(false);
  const spotifyPlayerHandler = (data) => {
    if (data === "play") {
      setPlayerPlaying(true);
    } else if (data === "pause") {
      setPlayerPlaying(false);
    }
  };

  const { textColorOne, bgColor } = useThemeColorsWithBg();

  const styleBg = {
    color: textColorOne,
    backgroundColor: bgColor,
  };

  const textColorTwo = useTextColor();

  const styleText = {
    color: textColorTwo,
  };

  const divStyle = {
    top: "70%",
    backgroundColor: bgColor,
  };

  const divStyleForPlayer = {
    top: "85%",
    backgroundColor: bgColor,
  };

  const playSongToggle = (
    <div className={classes.spotifyOptions}>
      <div style={styleText} className={classes.optionsLabel}>
        Play song during Timer:
      </div>
      <label className={classes.switch}>
        <input
          type="checkbox"
          checked={playSongsWhileTimer}
          onChange={handlePlayingSongs}
        />
        <span className={classes.slider}></span>
      </label>
    </div>
  );

  const inBuiltTrackGenerator = (
    songName,
    songTitle,
    songAuthor,
    musicCover
  ) => {
    return (
      <div className={classes.inBuiltPlaylist}>
        <div
          className={
            selectedSong === songName && isInbuiltPlaylistSelected
              ? `${classes.inBuiltPlaylist__song} ${classes.inBuiltPlaylist__selected}`
              : `${classes.inBuiltPlaylist__song}`
          }
          onClick={() => handlePlayPauseOfInbuiltPlaylist(songName)}
        >
          <img
            alt="Song Cover"
            src={musicCover}
            className={classes.inBuiltPlaylist__songCover}
          ></img>
          <div className={classes.songInfo}>
            <div className={classes.songTitle}>{songTitle}</div>
            <div className={classes.songAuthor}>{songAuthor}</div>
          </div>
        </div>
        <div className={classes.audioStyle}>
          <audio
            controls={true}
            className={classes.inBuildAudioRef}
            ref={audioRef}
            onEnded={() => {
              audioRef.current.currentTime = 0;
              audioRef.current.play();
            }}
            // paused={!inBuiltPlaylistPlaying}
            // paused={true}
          />
        </div>
      </div>
    );
  };

  const inbuiltPlaylistOne = inBuiltTrackGenerator(
    "night",
    "Night in Kyoto",
    "Yokonap",
    nightInKyotoCover
  );
  const inbuiltPlaylistTwo = inBuiltTrackGenerator(
    "airplane",
    "Airplane Mode",
    "Yokonap",
    airplaneCover
  );

  const inbuiltPlaylistThree = inBuiltTrackGenerator(
    "amongClouds",
    "Among Clouds",
    "Haquin",
    amongCloudsCover
  );

  const inbuiltPlaylistFour = inBuiltTrackGenerator(
    "beyondHorizon",
    "Beyond Horizon",
    "Yasumu",
    beyondHorizonCover
  );

  const inbuiltPlaylistFive = inBuiltTrackGenerator(
    "rainAmbience",
    "Rain Ambience",
    "Yasumu",
    rainCover
  );

  const noPremiumHandler = () => {
    props.logoutSpotify();
    // localStorage.removeItem("accessToken");
    // window.location.reload();
  };

  // Before login

  return (
    <section style={styleText} className={classes.sectionSpotifyLogin}>
      {status && accessToken && accessToken.length > 10 ? null : (
        <div
          style={
            status && accessToken && accessToken.length > 10
              ? divStyleForPlayer
              : divStyle
          }
          className={classes.musicConnect}
        >
          {/* Play Song Toggle */}
          {playSongToggle}
          {/* <div className={classes.spotifyConnect}>
            <div style={styleText} className={classes.connectHeading}>
              Connect your Spotify Premium account to listen to your favorite
              playlist while working...
            </div>
            <div className={classes.connectBtn} onClick={spotifyLoginHandler}>
              <img
                alt="Spotify Login Btn"
                src={SpotifyLogo}
                className={classes.spotifyLogo}
              ></img>
            </div>
          </div>

          <div style={styleText} className={classes.connectOrText}>
            Or
          </div> */}
          <div style={styleText} className={classes.tromoPlaylists}>
            Tromo Playlists
          </div>
          <div style={styleText} className={classes.tromoInbuilt__options}>
            {inbuiltPlaylistOne}
            {inbuiltPlaylistTwo}
            {inbuiltPlaylistThree}
            {inbuiltPlaylistFour}
            {inbuiltPlaylistFive}
          </div>
        </div>
      )}

      {status && accessToken && accessToken.length > 10 && (
        <div style={styleBg} className={classes.afterLoginSettings}>
          {/* Play song toggle */}
          {playSongToggle}
          <div
            onClick={() => {
              props.isInbuiltPlaylistSelected(false);
              localStorage.setItem("isInbuiltPlaylistSelected", false);
              localStorage.removeItem("selectedSong");
              setIsInbuiltPlaylistSelected(false);
            }}
            className={classes.playerSettings}
          >
            <SpotifyPlayer
              isPlayerPlaying={props.playerPlaying}
              passDeviceId={props.passDeviceId}
              onClick={() => {
                props.isInbuiltPlaylistSelected(false);
                localStorage.setItem("isInbuiltPlaylistSelected", false);
                localStorage.removeItem("selectedSong");
                setIsInbuiltPlaylistSelected(false);
              }}
              token={accessToken}
              noPremium={noPremiumHandler}
              refresh={localStorage.getItem("refreshToken")}
              // setNewAccess={(data) => setAccessToken(data)}
              isPlayerReady={props.isPlayerReady}
              // songToggleHandler={toggleSongSwitchHandler}
              // currentTrack={props.currentTrack}
            ></SpotifyPlayer>
          </div>
          <div style={styleText} className={classes.connectOrText}>
            Or
          </div>
          <div style={styleText} className={classes.tromoPlaylists}>
            Tromo Playlists
          </div>
          {/* Inbuilt Playlist */}
          <div style={styleText} className={classes.tromoInbuilt__options}>
            {inbuiltPlaylistOne}
            {inbuiltPlaylistTwo}
            {inbuiltPlaylistThree}
            {inbuiltPlaylistFour}
            {inbuiltPlaylistFive}
          </div>
        </div>
      )}
      {isOverlayOpen && (
        <LifetimeOverlay
          email={props.email}
          userName={props.userName}
          onClose={closeOverlay}
        />
      )}
    </section>
  );
};

export default SpotifyLogin;
