import classes from "./UpdatePlanModal.module.scss";
import Modal from "../UI/Modal";
import modalCross from "./../images/exit.png";
import { useState } from "react";

const UpdatePlanModal = (props) => {
  const [planGettingCancelled, setPlanGettingCancelled] = useState(false); // For showing loading when btn is clicked
  const [planGettingResumed, setPlanGettingResumed] = useState(false); // For showing loading when btn is clicked
  const planIsCancelled = props.subscription.cancel_at_period_end;

  const cancelPlanHandler = () => {
    const cancelSubscription = async () => {
      setPlanGettingCancelled(true);
      try {
        const response = await fetch(
          "https://snjy271pdj.execute-api.us-east-1.amazonaws.com/dev/payments/updatesubscription/cancelsubscription",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: props.jwtToken,
            },
            body: JSON.stringify({
              subscriptionId: props.subscriptionId,
              cancel_at_period: "true",
            }),
          }
        );

        const data = await response.json();

        props.hideModal();
        return data;
      } catch (error) {
        console.log("Error retrieving subscription:", error.message);

        props.hideModal();
      }
    };
    cancelSubscription();
  };

  const resumePlanHandler = () => {
    const resumeSubscription = async () => {
      setPlanGettingResumed(true);
      try {
        const response = await fetch(
          "https://snjy271pdj.execute-api.us-east-1.amazonaws.com/dev/payments/updatesubscription/cancelsubscription",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: props.jwtToken,
            },
            body: JSON.stringify({
              subscriptionId: props.subscriptionId,
              cancel_at_period: "false",
            }),
          }
        );

        const data = await response.json();

        props.hideModal();
        return data;
      } catch (error) {
        props.hideModal();
        console.log("Error retrieving subscription:", error.message);
      }
    };
    resumeSubscription();
  };

  return (
    <Modal className={classes.updatePlan}>
      <h2 className={classes.updatePlan__heading}>Update your Plan</h2>
      <img
        alt="Exit Modal icon"
        src={modalCross}
        className={classes.closeModal}
        onClick={props.hideModal}
      ></img>
      {!planIsCancelled && (
        <div className={classes.updatePlan__type}>
          <div className={classes["updatePlan__type--description"]}>
            Cancel your Plan:
          </div>

          {planGettingCancelled && <h2>Cancelling...</h2>}
          <button
            onClick={cancelPlanHandler}
            className={classes["update--btn"]}
          >
            Cancel Plan
          </button>

          <div className={classes.cautionNotice}>
            Note: Plan will stay active until the end of the current billing
            period. You can resume your subscription anytime with a click!
          </div>
        </div>
      )}
      {planIsCancelled && (
        <div className={classes.updatePlan__type}>
          <div className={classes["updatePlan__type--description"]}>
            Resume your Plan:
          </div>

          {planGettingResumed && <h2>Activating the plan...</h2>}
          <button
            onClick={resumePlanHandler}
            className={classes["update--btn"]}
          >
            Resume Plan
          </button>

          <div className={classes.cautionNotice}>
            Note: Your subscription will start again and payments will be
            resumed.
          </div>
        </div>
      )}
    </Modal>
  );
};

export default UpdatePlanModal;
