import Logo from "./../images/logo-black.png";
import styles from "./Footer.module.scss";

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.logoContainer}>
        <img src={Logo} alt="Company Logo" />
      </div>
      <div className={styles.linksContainer}>
        <a href="/terms-of-service">Terms</a>
        <a href="/privacy-policy">Privacy</a>
        <a href="mailto:support@tromodoro.com">Contact</a>
      </div>
    </footer>
  );
}

export default Footer;
