import classes from "./Modal.module.scss";
import { Fragment } from "react";

const Backdrop = (props) => {
  return (
    <div onClick={props.hideCartOnClick} className={classes.backdrop}>
      {props.children}
    </div>
  );
};

const ModalOverlay = (props) => {
  const finalClasses = `${classes.modal} ${props.className}`;

  return (
    <div className={finalClasses}>
      <div className={classes.content}>{props.children}</div>
    </div>
  );
};

const Modal = (props) => {
  return (
    <Fragment>
      <Backdrop hideCartOnClick={props.hideCartFunction}></Backdrop>
      <ModalOverlay className={props.className}>{props.children}</ModalOverlay>
    </Fragment>
  );
};

export default Modal;
