export const AmplifyDataObj = {
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: "us-east-1:2db9444b-5325-4061-8907-4e2416d2bfc0",

    // REQUIRED - Amazon Cognito Region
    region: "us-east-1",

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: "us-east-1",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "us-east-1_9qkqcWoBv",

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "7at0532epiohsf61t2q5lq555q",

    oauth: {
      domain: "tromoauth.auth.us-east-1.amazoncognito.com",
      // redirectSignIn: "http://localhost:3000/",
      // redirectSignOut: "http://localhost:3000/",
      redirectSignIn: "https://tromodoro.com/",
      redirectSignOut: "https://tromodoro.com/",
      responseType: "token", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
};

// Test Object:

// export const AmplifyDataObj = {
//   Auth: {
//     // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
//     // identityPoolId: "us-east-1:2db9444b-5325-4061-8907-4e2416d2bfc0",

//     // REQUIRED - Amazon Cognito Region
//     region: "us-east-1",

//     // OPTIONAL - Amazon Cognito Federated Identity Pool Region
//     // Required only if it's different from Amazon Cognito Region
//     identityPoolRegion: "us-east-1",

//     // OPTIONAL - Amazon Cognito User Pool ID
//     userPoolId: "us-east-1_KyHfk0Xmw",

//     // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
//     userPoolWebClientId: "3oer5o0r9akb7ie55ebenpcc32",

//     oauth: {
//       domain: "tromotestauth.auth.us-east-1.amazoncognito.com",
//       redirectSignIn: "http://localhost:3000/",
//       redirectSignOut: "http://localhost:3000/",
//       responseType: "token", // or 'token', note that REFRESH token will only be generated when the responseType is code
//     },
//   },
// };
