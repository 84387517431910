import { useEffect, useState } from "react";
import classes from "./SpotifyPlayer.module.scss";
import { SpotifyRefreshAccessToken } from "../../topVars";
import useTextColor from "../../hooks/use-theme-text";

const track = {
  name: "",
  album: {
    images: [{ url: "" }],
  },
  artists: [{ name: "" }],
};

const PLAYLISTS = "https://api.spotify.com/v1/me/playlists";
const DEVICES = "https://api.spotify.com/v1/me/player/devices";
const PLAY = "https://api.spotify.com/v1/me/player/play";
// const PAUSE = "https://api.spotify.com/v1/me/player/pause";
// const NEXT = "https://api.spotify.com/v1/me/player/next";
// const PREVIOUS = "https://api.spotify.com/v1/me/player/previous";
// const PLAYER = "https://api.spotify.com/v1/me/player";
const TRACKS = "https://api.spotify.com/v1/playlists/{{PlaylistId}}/tracks";
// const CURRENTLYPLAYING =
//   "https://api.spotify.com/v1/me/player/currently-playing";
const SHUFFLE = "https://api.spotify.com/v1/me/player/shuffle";

const SpotifyPlayer = (props) => {
  const [playSongsWhileTimer, setPlaySongsWhileTimer] = useState(
    localStorage.getItem("isPlaySongWhileTimerToggleOn") === "true"
      ? true
      : false
  ); // Toggle Switch

  // useEffect(() => {
  //   if (playSongsWhileTimer === false) {
  //     props.songToggleHandler(false);
  //   }
  // });

  // Toggle Switch Change handling
  // const handlePlayingSongs = () => {
  //   props.songToggleHandler(!playSongsWhileTimer);
  //   localStorage.setItem(
  //     "isPlaySongWhileTimerToggleOn",
  //     `${!playSongsWhileTimer}`
  //   );
  //   setPlaySongsWhileTimer(!playSongsWhileTimer);
  // };

  // Refresh access Token
  const refreshAccessToken = async () => {
    try {
      const response = await fetch(
        `${SpotifyRefreshAccessToken}?refresh_token=${props.refresh}`
      );
      const data = await response.json();
      const currAccessToken = data.accessToken;

      if (
        typeof currAccessToken !== "object" &&
        typeof refreshToken !== "object"
      ) {
        // Storing the accessToken in Local storage
        localStorage.removeItem("accessToken");
        localStorage.setItem("accessToken", currAccessToken);
        props.setNewAccess(currAccessToken);
      }

      return currAccessToken;
    } catch (error) {
      console.error(error);
    }
  };

  // const [currentDevice, setCurrentDevice] = useState(null);
  const [deviceOptions, setDeviceOptions] = useState({
    currentDevice: [],
    allDevices: [],
  });
  const [playlistOptions, setPlaylistOptions] = useState({
    currentPlaylist: [],
    allPlaylists: [],
  });

  const [trackOptions, setTrackOptions] = useState({
    currentTrack: [],
    currentTrackIndex: 0,
    allTracks: [],
  });

  const [isPremium, setIsPremium] = useState(false);

  // Call API
  async function callApi(method, url, body, type) {
    let response;
    if (body === null) {
      response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + props.token,
        },
        // body: JSON.stringify(body)
      });
    } else {
      response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + props.token,
        },
        body: JSON.stringify(body),
      });
    }
    const data = await response.json();

    // const responseBody = await response.json();
    // console.log(responseBody);

    if (response.status === 200) {
      if (type === "devices") {
        let allDevices = [];
        data.devices.forEach((item) => allDevices.push(item));
        setDeviceOptions({
          currentDevice: allDevices[0],
          allDevices: allDevices,
        });
      } else if (type === "playlist") {
        let allPlaylists = [];
        data.items.forEach((item) => allPlaylists.push(item));
        if (localStorage.getItem("lastPlaylist")) {
          const lastPlaylistName = localStorage.getItem("lastPlaylistName");
          const lastPlaylistObject = allPlaylists.find(
            (playlist) => playlist.name === lastPlaylistName
          );
          if (lastPlaylistObject) {
            setPlaylistOptions({
              currentPlaylist: lastPlaylistObject,
              allPlaylists: allPlaylists,
            });
          } else {
            setPlaylistOptions({
              currentPlaylist: allPlaylists[0],
              allPlaylists: allPlaylists,
            });
          }
        } else {
          setPlaylistOptions({
            currentPlaylist: allPlaylists[0],
            allPlaylists: allPlaylists,
          });
        }
      } else if (type === "track") {
        let allTracks = [];
        data.items.forEach((item) => allTracks.push(item));
        setTrackOptions({
          currentTrack: allTracks[0],
          allTracks: allTracks,
          currentTrackIndex: trackOptions.currentTrackIndex,
        });
      } else if (type === "play") {
        // Playing the track
      }
    } else if (response.status === 401) {
      refreshAccessToken();
    } else {
      // alert(response.responseText);
    }

    if (data.error.reason === "PREMIUM_REQUIRED") {
      setIsPremium(true);
    }
  }

  // Getting Devices
  const refreshDevices = () => {
    callApi("GET", DEVICES, null, "devices");
  };

  const DeviceChangeHandler = (event) => {
    const selectedOption = deviceOptions.allDevices.find(
      (option) => option.name === event.target.value
    );
    setDeviceOptions({
      currentDevice: selectedOption,
      allDevices: deviceOptions.allDevices,
    });
  };

  // For Playlists
  function refreshPlaylists() {
    callApi("GET", PLAYLISTS, null, "playlist");
  }

  const playlistChangeHandler = (event) => {
    const selectedOption = playlistOptions.allPlaylists.find(
      (option) => option.name === event.target.value
    );

    localStorage.setItem("lastPlaylistName", selectedOption.name);
    setPlaylistOptions({
      currentPlaylist: selectedOption,
      allPlaylists: playlistOptions.allPlaylists,
    });
  };

  // For Tracks

  function refreshTracks() {
    const playlistID = playlistOptions.currentPlaylist.id;
    try {
      if (playlistID.length > 0) {
        const url = TRACKS.replace("{{PlaylistId}}", playlistID);
        callApi("GET", url, null, "track");
      }
    } catch (error) {}
  }

  const tracksChangeHandler = (event) => {
    const selectedOption = trackOptions.allTracks.find(
      (option) => option.track.name === event.target.value
    );
    const index = trackOptions.allTracks.indexOf(selectedOption);

    setTrackOptions({
      currentTrack: selectedOption,
      currentTrackIndex: index,
      allTracks: trackOptions.allTracks,
    });
  };

  // Play, Pause and more

  const playHandler = async () => {
    try {
      let playlist_id = playlistOptions.currentPlaylist.id;
      let device_id = deviceOptions.currentDevice.id;
      // let trackindex = trackOptions.currentTrack.track.track_number - 1;
      let trackindex = trackOptions.currentTrackIndex;

      let body = {};
      body.context_uri = "spotify:playlist:" + playlist_id;
      body.offset = {};
      body.offset.position = trackindex > 0 ? Number(trackindex) : 0;
      // body.offset.position = 0;
      body.offset.position_ms = 0;

      await callApi("PUT", PLAY + "?device_id=" + device_id, body, "playing");

      // For Shuffling Tracks

      await callApi(
        "PUT",
        SHUFFLE + "?state=true&device_id=" + deviceOptions.currentDevice.id,
        null,
        "playing"
      );
      // player.nextTrack();
      // player.togglePlay();
    } catch {}
  };

  useEffect(() => {
    const refreshDeviceAndPlaylist = async () => {
      await refreshDevices();
      await refreshPlaylists();
      await refreshTracks();
    };

    refreshDeviceAndPlaylist();
  }, [props.isPlayerReady]);

  useEffect(() => {
    try {
      refreshTracks();
      setTrackOptions({
        currentTrack: trackOptions.allTracks[0],
        allTracks: trackOptions.allTracks,
        currentTrackIndex: 0,
      });
      playHandler();
    } catch {}
  }, [playlistOptions]);

  useEffect(() => {
    try {
      playHandler();
    } catch {}
  }, [trackOptions]);

  // useEffect(() => {
  //   setTrackOptions({
  //     currentTrack: props.current_track,
  //     allTracks: trackOptions.allTracks,
  //     currentTrackIndex: 0,
  //   });
  // },[props.current_track])

  const default_device_id = props.passDeviceId;

  if (deviceOptions.currentDevice) {
    // console.log("Device Options");
    // console.log(deviceOptions.currentDevice.id);
  }

  const textColorTwo = useTextColor();

  const style = {
    color: textColorTwo,
  };
  const [premiumNotAvailable, setPremiumNotAvailable] = useState(false);
  const spotifyPremiumHandler = () => {
    // props.noPremium();
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    window.location.reload();
  };

  return (
    <div className={classes.SpotifyPlayer}>
      {props.isPlayerReady ? (
        <div className={classes.spotifySettings}>
          {/* <div className={classes.spotifyOptions}>
            <div className={classes.optionsLabel}>Play song during Timer:</div>
            <label className={classes.switch}>
              <input
                type="checkbox"
                checked={playSongsWhileTimer}
                onChange={handlePlayingSongs}
              />
              <span className={classes.slider}></span>
            </label>
          </div> */}

          <div className={classes.spotifyOptions}>
            <label style={style} className={classes.optionsLabel}>
              Device:
            </label>
            <select
              onChange={DeviceChangeHandler}
              className={classes.optionsControl}
              value={default_device_id}
            >
              {deviceOptions.allDevices.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
            <input
              type="button"
              onClick={refreshDevices}
              value="Refresh Devices"
              className={classes.optionsInput}
            ></input>
          </div>

          <div className={classes.spotifyOptions}>
            <label style={style} className={classes.optionsLabel}>
              Playlist:
            </label>
            <select
              onChange={playlistChangeHandler}
              className={classes.optionsControl}
              value={playlistOptions.currentPlaylist.name}
            >
              {playlistOptions.allPlaylists.map((option) => (
                <option key={option.id} value={option.name}>
                  {option.name}
                </option>
              ))}
            </select>
            <input
              type="button"
              onClick={refreshPlaylists}
              value="Refresh Playlists"
              className={classes.optionsInput}
            ></input>
          </div>

          {/* For Tracks */}
          <div className={classes.spotifyOptions}>
            <label style={style} className={classes.optionsLabel}>
              Tracks:
            </label>
            <select
              onChange={tracksChangeHandler}
              className={classes.optionsControl}
              // value={trackOptions.currentTrack.track.name}
            >
              {trackOptions.allTracks.map((option) => (
                <option key={option.track.id} value={option.track.name}>
                  {option.track.name}
                </option>
              ))}
            </select>
            <input
              type="button"
              onClick={refreshTracks}
              value="Refresh Tracks"
              className={classes.optionsInput}
            ></input>
          </div>
          <input
            type="button"
            onClick={playHandler}
            value="Load Player"
            className={classes.playTrackBtn}
          ></input>
        </div>
      ) : (
        <h3>
          {isPremium && (
            <div
              className={classes.spotifyPremiumRequired}
              onClick={spotifyPremiumHandler}
            >
              Sorry, This feature requires Spotify Premium.Click here to go
              back!
            </div>
          )}
          {!isPremium && "Player is loading"}
        </h3>
      )}
    </div>
  );
};

export default SpotifyPlayer;
