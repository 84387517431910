import React from "react";
import styles from "./LifetimeDealButton.module.scss";
import crownIcon from "./../images/crown.png";
import LifetimeOverlay from "../components/LifetimeOverlay/LifetimeOverlay";
import { useState } from "react";

const LifetimeDealButton = (props) => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const openOverlay = () => {
    setIsOverlayOpen(true);
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
  };
  return (
    <>
      <div className={styles.button} onClick={openOverlay}>
        <img
          src={crownIcon}
          alt="Crown Icon"
          className={styles.crownIcon}
        ></img>
        <span>Lifetime deal</span>
      </div>
      {isOverlayOpen && (
        <LifetimeOverlay
          email={props.email}
          userName={props.userName}
          onClose={closeOverlay}
        />
      )}
    </>
  );
};

export default LifetimeDealButton;
