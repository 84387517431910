import classes from "./TimerBox.module.scss";
import Button from "../../UI/Button";
import { useState, useEffect, useRef } from "react";
import magicChimeMusic from "./../../images/magic-chime-short.mp3";
import applauseMusic from "./../../images/applause.mp3";
import alarmEnding from "./../../images/alarmEnding.mp3";
import Status from "../../auth/Status";
import Mode from "./Mode";
import { secondsToMMSS } from "./secondsToMMSS";
import worker_script from "./tromoworker";
import chrome from "chrome-extension-async";
import useThemeColorsWithBg from "../../hooks/use-theme";
import { BlockExtensionId } from "../../topVars";
import LifetimeOverlay from "../LifetimeOverlay/LifetimeOverlay";

let TromodoroTiming;
let shortWorkTiming;
let breakTiming;

if (localStorage.getItem("timings")) {
  // Getting the data if it was saved in local storage while changing

  const data = JSON.parse(localStorage.getItem("timings"));
  TromodoroTiming = parseFloat(data.work).toFixed(2);
  shortWorkTiming = parseFloat(data.shortWork).toFixed(2);
  breakTiming = parseFloat(data.break).toFixed(2);
} else {
  // Loading the default Tromodoro Timings
  TromodoroTiming = parseFloat((45 * 60).toFixed(2));
  shortWorkTiming = parseFloat((15 * 60).toFixed(2));
  breakTiming = parseFloat((10 * 60).toFixed(2));
}

const TimerBox = (props) => {
  const status = Status();
  const [mode, setMode] = useState("work");
  const [seconds, setSeconds] = useState(TromodoroTiming);
  const [isRunning, setIsRunning] = useState(false);
  const [timer, setTimer] = useState(null);
  const chrome = window.chrome;
  const [blockOptionsChanged, setBlockOptionsChanged] = useState(false);

  useEffect(() => {
    if (props.optionChanged) {
      setBlockOptionsChanged(true);
    } else {
      setBlockOptionsChanged(false);
    }
  }, [props.optionChanged]);

  const blockOption = localStorage.getItem("blockOption")
    ? localStorage.getItem("blockOption")
    : "tromodoro";

  useEffect(() => {
    if (props.currTimer === "pomo") {
      setMode("work");
    }
  }, [props.currTimer]);

  useEffect(() => {
    let allTimings = {
      work: props.tromodoroNewTimings.work,
      shortWork: props.tromodoroNewTimings.shortWork,
      break: props.tromodoroNewTimings.break,
    };

    if (localStorage.getItem("timings")) {
      const data = JSON.parse(localStorage.getItem("timings"));

      allTimings.work = data.work;
      allTimings.shortWork = data.shortWork;
      allTimings.break = data.break;
    }

    TromodoroTiming = parseFloat((allTimings.work * 60).toFixed(2));
    shortWorkTiming = parseFloat((allTimings.shortWork * 60).toFixed(2));
    breakTiming = parseFloat((allTimings.break * 60).toFixed(2));

    // TromodoroTiming = parseFloat((10).toFixed(2)); // temp for testing
    // shortWorkTiming = parseFloat((10).toFixed(2)); // temp for testing
    // breakTiming = parseFloat((60).toFixed(2)); // temp for testing
    if (mode === "work") {
      setSeconds(TromodoroTiming);
    } else if (mode === "short-work") {
      setSeconds(shortWorkTiming);
    } else {
      setSeconds(breakTiming);
    }
  }, [
    props.tromodoroNewTimings.work,
    props.tromodoroNewTimings.shortWork,
    props.tromodoroNewTimings.break,
    mode,
  ]);

  // Use the useRef hook to create a reference to the audio element
  const startTimerRef = useRef(null);
  const endTimerRef = useRef(null);
  const endBreakRef = useRef(null);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const openOverlay = () => {
    setIsOverlayOpen(true);
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
  };
  const loggedInStatus = Status;

  const SetRunningToggle = () => {
    if (!loggedInStatus || props.planType !== "paid") {
      setIsOverlayOpen(true);
      return;
      // props.showProVersion();
      // history.push("/login");
    }

    // To play the sound
    if (!isRunning) {
      startTimerRef.current.play();
      console.log("Has started Playing");
      props.playSpotify("play");

      // Setting up notification
      if (mode === "work") {
        props.notificationHandler({ type: "work-started" });
        // For Task Manipulation
        localStorage.setItem("taskManipulated", false);

        // Send Message to Tromo Website Blocker
        try {
          if (blockOption === "tromodoro" || blockOption === "tromoShortWork") {
            try {
              chrome.runtime.sendMessage(BlockExtensionId, {
                startWork: true,
              });

              props.enableBlocker();
            } catch (error) {}
          }
        } catch (error) {}
      } else if (mode === "short-work") {
        props.notificationHandler({ type: "short-work-started" });
        // For Task Manipulation
        localStorage.setItem("taskManipulated", false);

        // Send Message to Tromo Website Blocker
        if (blockOption === "tromoShortWork" || blockOption === "shortWork") {
          try {
            chrome.runtime.sendMessage(BlockExtensionId, {
              startWork: true,
            });

            props.enableBlocker();
          } catch (error) {}
        }
      } else if (mode === "break") {
        props.notificationHandler({ type: "break-started" });
        // For Task Manipulation
        localStorage.setItem("taskManipulated", false);
      }
    } else {
      props.playSpotify("pause");
      // Dont enable Tromo Website Blocked if plan is not paid

      try {
        chrome.runtime.sendMessage(BlockExtensionId, {
          stopWork: true,
        });
        props.disableBlockerHandler();
      } catch (error) {}
    }

    setIsRunning(!isRunning);
  };

  const [worker, setWorker] = useState(null);

  // Main Timer Logic
  useEffect(() => {
    // Setting up a timer worker
    const workerInstance = new Worker(worker_script);
    workerInstance.postMessage({ isRunning: isRunning, seconds: seconds });

    workerInstance.onmessage = (event) => {
      setSeconds(event.data.seconds);
    };

    return () => {
      workerInstance.terminate();
    };
  }, [isRunning]);

  useEffect(() => {
    if (seconds === 0) {
      // Send the notification that the timer has ended
      setIsRunning(false);
      props.playSpotify("pause");
      if (mode === "work") {
        props.notificationHandler({ type: "work-ended" });
        try {
          chrome.runtime.sendMessage(BlockExtensionId, {
            stopWork: true,
          });
          props.disableBlockerHandler();
        } catch (error) {}
        // Play sound when work timer ends
        endTimerRef.current.play();
        if (props.currTimer === "tromo") {
          setMode("short-work");
          setSeconds(shortWorkTiming);
        } else if (props.currTimer === "pomo") {
          setMode("break");
          setSeconds(breakTiming);
        }
      } else if (mode === "short-work") {
        props.notificationHandler({ type: "short-work-ended" });

        try {
          chrome.runtime.sendMessage(BlockExtensionId, {
            stopWork: true,
          });
          props.disableBlockerHandler();
        } catch (error) {}
        // Play sound when work timer ends
        endTimerRef.current.play();
        setMode("break");
        setSeconds(breakTiming);
      } else if (mode === "break") {
        props.notificationHandler({ type: "break-ended" });
        // Play sound when Break ends
        endBreakRef.current.play();
        setMode("work");
        setSeconds(TromodoroTiming);
      }
    }
  }, [seconds, mode, props]);

  const formattedTime = secondsToMMSS(seconds);

  if (isRunning) {
    document.title = `${formattedTime} - Let's go!`;
  } else {
    document.title = "Tromodoro - The Advanced Pomodoro Timer";
  }

  // After clicking the other timer button when timer is running
  const changeMode = (newMode, modeTiming) => {
    if (mode !== newMode && isRunning) {
      if (window.confirm("Are you sure you want to leave the current Timer?")) {
        setMode(newMode);
        setIsRunning(false);
        setSeconds(modeTiming);
        try {
          chrome.runtime.sendMessage(BlockExtensionId, {
            stopWork: true,
          });
          props.disableBlockerHandler();
        } catch (error) {}
        props.playSpotify("pause");
      }
    } else if (mode !== newMode && !isRunning) {
      setMode(newMode);
      setIsRunning(false);
      setSeconds(modeTiming);
    }
  };

  const tromodoroTimeHandler = () => changeMode("work", TromodoroTiming);
  const shortWorkTimerHandler = () => changeMode("short-work", shortWorkTiming);
  const breakTimerHandler = () => changeMode("break", breakTiming);

  useEffect(() => {
    props.getMode(mode);
  }, [mode, props]);

  // let textColor = "#fff";
  // let bgColor = "#2F2F2F";
  // if (localStorage.getItem("theme") === "beach") {
  //   textColor = "#142747";
  //   bgColor = "#fff";
  // } else {
  //   textColor = "#fff";
  //   bgColor = "#2F2F2F";
  // }

  // const [style, setStyle] = useState({
  //   color: textColor,
  //   backgroundColor: bgColor,
  // });

  const { textColor, bgColor } = useThemeColorsWithBg();

  const style = {
    color: textColor,
    backgroundColor: bgColor,
  };

  // useEffect(() => {
  //   if (localStorage.getItem("theme") === "dark") {
  //     setStyle({
  //       color: "#fff",
  //     });
  //   } else {
  //     setStyle({
  //       color: "#2F2F2F",
  //     });
  //   }
  // }, [props.currStyle]);

  return (
    <div className={classes.mainTimer}>
      <div style={style} className={classes.timerBox}>
        <ul className={classes.timerBox__list}>
          <Mode
            afterClick={tromodoroTimeHandler}
            modeEntered={mode}
            currCategory="work"
            catText={props.currTimer === "tromo" ? "Tromodoro" : "Pomodoro"}
            currStyle={props.style}
          ></Mode>
          {props.currTimer === "tromo" && (
            <Mode
              afterClick={shortWorkTimerHandler}
              modeEntered={mode}
              currCategory="short-work"
              catText="Short Work"
              currStyle={props.style}
            ></Mode>
          )}

          <Mode
            afterClick={breakTimerHandler}
            modeEntered={mode}
            currCategory="break"
            catText="Break"
            currStyle={props.style}
          ></Mode>
        </ul>
        <div style={style} className={classes.timerBox__time}>
          {formattedTime}
        </div>
        <div onClick={SetRunningToggle} className={classes.start__timer}>
          <Button
            text={isRunning ? "Pause" : "Start"}
            className={classes.btn_extraStyle}
          ></Button>
          <audio ref={startTimerRef} src={magicChimeMusic}></audio>
          <audio ref={endTimerRef} src={applauseMusic}></audio>
          <audio ref={endBreakRef} src={alarmEnding}></audio>
        </div>
        {isOverlayOpen && (
          <LifetimeOverlay
            email={props.email}
            userName={props.userName}
            onClose={closeOverlay}
          />
        )}
      </div>
    </div>
  );
};

export default TimerBox;
