import React, { useEffect } from "react";
import classes from "./WallOfLove.module.scss";

const WallOfLove = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://testimonial.to/js/iframeResizer.min.js";
    script.type = "text/javascript";
    script.async = true;

    const handleScriptLoad = () => {
      window.iFrameResize(
        { log: false, checkOrigin: false },
        "#testimonialto-tromodoro-tag-all-dark"
      );
    };

    script.addEventListener("load", handleScriptLoad);

    document.body.appendChild(script);

    return () => {
      script.removeEventListener("load", handleScriptLoad);
      document.body.removeChild(script);
    };
  }, []);

  return (
    <section className={classes.wallOfLove}>
      <h2 className={classes.wallOfLoveHeading}>Our Wall of Love</h2>
      <iframe
        id="testimonialto-tromodoro-tag-all-dark"
        title="Testimonial Widget"
        className={classes.wallOfLoveFrame}
        src="https://embed-v2.testimonial.to/w/tromodoro?theme=dark&card=base&loadMore=on&initialCount=20&tag=all"
        frameBorder="0"
        scrolling="no"
        width="100%"
        height="800px" /* Set a fixed height for better visibility */
      ></iframe>
    </section>
  );
};

export default WallOfLove;
