import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
// import "whatwg-fetch";
// import "cross-fetch/polyfill";
import "./index.scss";
import App from "./App";

const MyApp = () => {
  return (
    <div>
      <App></App>
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <MyApp />
  </BrowserRouter>
);
