import { useState, useEffect } from "react";

function useThemeColorsWithBg() {
  const [colors, setColors] = useState({
    textColor: "#fff",
    bgColor: "#2F2F2F",
  });

  useEffect(() => {
    const currentTheme = localStorage.getItem("theme");
    if (currentTheme === "light") {
      setColors({
        textColor: "#142747",
        bgColor: "#fff",
      });
    } else if (currentTheme === "beach") {
      setColors({
        textColor: "#fff",
        bgColor: "#2F2F2F",
      });
    } else {
      setColors({
        textColor: "#fff",
        bgColor: "#2F2F2F",
      });
    }
  }, []);

  return colors;
}

export default useThemeColorsWithBg;
