import classes from "./AnalyticsModal.module.scss";
import Modal from "../../UI/Modal";
import crossIcon from "./../../images/settings.png";
import celebrationImage from "./../../images/celebration.png";
// import downArrow from "./../../images/downArrow.png";
import { useEffect, useState } from "react";
import Status from "../../auth/Status";
import { useContext } from "react";
import { AccountContext } from "../../auth/Account";
import { Oval } from "react-loader-spinner";

function extractUserId(jwtToken) {
  // extract the userId from the JWT token
  const parts = jwtToken.split(".");
  const payload = JSON.parse(atob(parts[1]));
  return payload.sub;
}

const AnalyticsModal = (props) => {
  const status = Status();
  const { getSession } = useContext(AccountContext);
  const [selectedDate, setSelectedDate] = useState("today");
  const [hoursWorked, setHoursWorked] = useState(null);
  const [tasksCompleted, setTasksCompleted] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (status) {
      getSession().then((session) => {
        const jwtToken = session.getIdToken().getJwtToken();

        // Get data function
        const getData = async (jwt) => {
          try {
            const resp = await fetch(
              `https://snjy271pdj.execute-api.us-east-1.amazonaws.com/dev/tromo-user-activities/get-hours?userId=${extractUserId(
                jwt
              )}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: jwt,
                },
              }
            );

            const data = await resp.json();

            setHoursWorked(data);

            return data;
          } catch {}
        };

        // Get Activites function
        const getActivities = async (jwt, pageNum) => {
          try {
            const resp = await fetch(
              `https://snjy271pdj.execute-api.us-east-1.amazonaws.com/dev/tromo-user-activities/get-activities?userId=${extractUserId(
                jwt
              )}&page=${pageNum}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: jwt,
                },
              }
            );

            const data = await resp.json();

            setTasksCompleted(data);

            return data;
          } catch {}
        };

        // Calling getdata function
        getData(jwtToken);

        getActivities(jwtToken, currentPage);
      });
    }
  }, [getSession, status, currentPage]);

  function handleDateSelect(event) {
    setSelectedDate(event.target.value);
  }

  let currentMessage = null;

  if (hoursWorked) {
    if (selectedDate === "today") {
      const totalMinutes = hoursWorked["Total Minutes (Today)"];
      const totalHours = Math.round(totalMinutes / 60);
      if (totalHours === 0) {
        currentMessage =
          "These are Rookie numbers. We need to pump it up. Let's go! 🚀";
      } else if (totalHours > 0 && totalHours < 3) {
        currentMessage =
          "Not bad, But you can do better. Let's complete those tasks!! 🚀";
      } else if (totalHours >= 3) {
        currentMessage = `Woo-hoo! You totally rocked it and conquered a whopping ${totalHours} hours of work today. Congratulations! 🎉 🚀`;
      }
    } else if (selectedDate === "yesterday") {
      const totalMinutes = hoursWorked["Total Minutes (Yesterday)"];
      const totalHours = Math.round(totalMinutes / 60);
      if (totalHours === 0) {
        currentMessage =
          "Yesterday might have been a rest day, but let's get back on track today and tackle those tasks like a champion! Let's go! 🚀";
      } else if (totalHours > 0 && totalHours < 3) {
        currentMessage =
          "Yesterday was a great day, but today can be a banger. Let's tackle todays tasks! 🚀";
      } else if (totalHours >= 3) {
        currentMessage = `Woah! Yesterday was a bang. Let's make today count as well!🔥🚀`;
      }
    } else if (selectedDate === "this-month") {
      const totalMinutes = hoursWorked["Total Minutes (This month)"];
      const totalHours = Math.round(totalMinutes / 60);
      if (totalHours === 0) {
        currentMessage =
          "It's time to get this month started with some work! Let's go! 🚀";
      } else if (totalHours > 0 && totalHours < 40) {
        currentMessage = `You completed around ${totalHours} hours of work. This is no less feat. Excited to see the complete progress of this month!`;
      } else if (totalHours >= 40) {
        currentMessage = `Woah! This month is already a banger. Excited to see how it ends!🔥🚀`;
      }
    } else if (selectedDate === "last-month") {
      const totalMinutes = hoursWorked["Total Minutes (Previous month)"];
      const totalHours = Math.round(totalMinutes / 60);
      if (totalHours === 0) {
        currentMessage =
          "Cannot see Last months tasks. Maybe it was a break. Let's make this month count!";
      } else if (totalHours > 0 && totalHours < 40) {
        currentMessage = `You completed around ${totalHours} hours of work. This is no less feat. Let's make this month count as well!`;
      } else if (totalHours >= 40) {
        currentMessage = `Woah! Last month was a banger. Let's make this one super Productive as well!🔥🚀`;
      }
    }
  }

  // const filteredTasks = tasksCompleted?.taskDetails.filter(
  //   (task) => task.taskName !== ""
  // );

  return (
    <Modal
      hideCartFunction={props.onHideAnalyticsModal}
      className={classes.analytics}
    >
      <div className={classes.analytics__info}>
        <h2 className={classes.analytics__title}>Analytics</h2>
        <img
          alt="Exit icon"
          src={crossIcon}
          className={classes.exitAnalyticsIcon}
          onClick={props.onHideAnalyticsModal}
        ></img>
      </div>
      <div className={classes.HoursData}>
        <div className={classes.selectDate}>
          {/* <div className={classes.selectDate__value}>Today</div> */}
          <select
            value={selectedDate}
            onChange={handleDateSelect}
            className={classes.selectDate__value}
          >
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="this-month">This Month</option>
            <option value="last-month">Last Month</option>
          </select>
        </div>
        <h2 className={classes.HoursData__primary}>
          {status && !hoursWorked && (
            <Oval
              height={20}
              width={20}
              color="#fff"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#4fa94d"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          )}
          {status && hoursWorked && (
            <>
              {selectedDate === "today" && (
                <>
                  {hoursWorked["Total Minutes (Today)"] > 0 ? (
                    <>
                      {Math.floor(hoursWorked["Total Minutes (Today)"] / 60)}{" "}
                      Hr: {hoursWorked["Total Minutes (Today)"] % 60} min
                    </>
                  ) : (
                    <>No time worked today.</>
                  )}
                </>
              )}
              {selectedDate === "yesterday" && (
                <>
                  {hoursWorked["Total Minutes (Yesterday)"] > 0 ? (
                    <>
                      {Math.floor(
                        hoursWorked["Total Minutes (Yesterday)"] / 60
                      )}{" "}
                      Hr: {hoursWorked["Total Minutes (Yesterday)"] % 60} min
                    </>
                  ) : (
                    <>No time worked yesterday.</>
                  )}
                </>
              )}
              {selectedDate === "this-month" && (
                <>
                  {hoursWorked["Total Minutes (This month)"] > 0 ? (
                    <>
                      {Math.floor(
                        hoursWorked["Total Minutes (This month)"] / 60
                      )}{" "}
                      Hr: {hoursWorked["Total Minutes (This month)"] % 60} min
                    </>
                  ) : (
                    <>No time worked this month.</>
                  )}
                </>
              )}
              {selectedDate === "last-month" && (
                <>
                  {hoursWorked["Total Minutes (Previous month)"] > 0 ? (
                    <>
                      {Math.floor(
                        hoursWorked["Total Minutes (Previous month)"] / 60
                      )}{" "}
                      Hr: {hoursWorked["Total Minutes (Previous month)"] % 60}{" "}
                      min
                    </>
                  ) : (
                    <>No time worked last month.</>
                  )}
                </>
              )}
            </>
          )}

          {/* <span>
            <img
              src={celebrationImage}
              className={classes.celebrationImage}
              alt="Celebration icon"
            ></img>
          </span> */}
        </h2>

        {!status && (
          <h3 className={classes.HoursData__secondary}>
            To see your hours worked, please log in.
          </h3>
        )}
        {currentMessage && (
          <h3 className={classes.HoursData__secondary}>{currentMessage}</h3>
        )}

        <div className={classes.activitiesData}>
          <h2 className={classes.activitiesData__primary}>Activity:</h2>
          <div className={classes.activityBox}>
            {status && !tasksCompleted ? (
              <Oval
                height={20}
                width={20}
                color="#fff"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#4fa94d"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            ) : (
              tasksCompleted?.taskDetails &&
              tasksCompleted?.taskDetails.map((task) => (
                <div className={classes.activityEach}>
                  <h3 className={classes.activityTitle}>{task.taskName}</h3>
                  <div className={classes.activityMinute}>{task.minutes}M</div>
                  <div className={classes.activityCompleted}>
                    <div className={classes.completed__date}>
                      {new Date(`${task.date} ${task.time}`).toLocaleString(
                        "en-US",
                        {
                          weekday: "short",
                          month: "short",
                          day: "numeric",
                        }
                      )}
                    </div>
                    <div className={classes.completed__time}>
                      {new Date(`${task.date} ${task.time}`).toLocaleString(
                        "en-US",
                        {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: false,
                          timeZoneName: "short",
                          timeZone: "UTC",
                        }
                      )}
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
          {!status && <h3>Log in to access the activities data</h3>}
          {status && tasksCompleted && (
            <div>
              {[...Array(tasksCompleted.totalPages)].map((_, index) => {
                // Calculate the first and last page numbers to display
                const firstPage = Math.max(1, currentPage - 2);
                const lastPage = Math.min(
                  tasksCompleted.totalPages,
                  firstPage + 4
                );

                // Determine whether to show this button or not
                const showButton =
                  index + 1 >= firstPage && index + 1 <= lastPage;

                // Determine whether to show the last button
                const showLastButton =
                  index + 1 === tasksCompleted.totalPages &&
                  lastPage < tasksCompleted.totalPages;

                // Render the button if it should be shown
                if (showButton) {
                  return (
                    <button
                      key={index}
                      onClick={() => handlePageChange(index + 1)}
                      className={
                        currentPage === index + 1
                          ? `${classes.btnPage} ${classes.active}`
                          : `${classes.btnPage}`
                      }
                      style={{
                        fontWeight:
                          currentPage === index + 1 ? "bold" : "normal",
                      }}
                    >
                      {index + 1}
                    </button>
                  );
                }

                // Render the last button if it should be shown
                if (showLastButton) {
                  return (
                    <button
                      key={index}
                      onClick={() =>
                        handlePageChange(tasksCompleted.totalPages)
                      }
                      className={classes.btnPage}
                    >
                      Last
                    </button>
                  );
                }

                // Render nothing if this button should be hidden
                return null;
              })}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AnalyticsModal;
