import useInput from "../hooks/use-input";
import { useState } from "react";
import classes from "./SignUp.module.scss";
import { useHistory } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import { AmplifyDataObj } from "../auth/AmplifyPool";
import GoogleIcon from "./../images/google-icon.png";
import rightArrow from "./../images/rightGreenArrow.png";
import MainLogo from "../UI/MainLogo";
import { ToastContainer, toast } from "react-toastify";
import useThemeColorsWithBg from "../hooks/use-theme";
import useTextColor from "../hooks/use-theme-text";
import "react-toastify/dist/ReactToastify.css";
import { Oval } from "react-loader-spinner";

const Signup = (props) => {
  // Always keep the below code in component, since it cause redirection
  Amplify.configure(AmplifyDataObj);
  Auth.configure(AmplifyDataObj);

  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const {
    value: emailEntered,
    isValid: emailValid,
    hasError: emailInputInvalid,
    valueChangeHandler: emailHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmailValues,
  } = useInput((value) => value.trim() !== "" && value.includes("@"));

  const {
    value: usernameEntered,
    isValid: userNameValid,
    hasError: userNameInvalid,
    valueChangeHandler: userNameHandler,
    inputBlurHandler: userNameBlurHandler,
    reset: resetUserNameValues,
  } = useInput((value) => value.trim() !== "");

  const {
    value: passEntered,
    isValid: passValid,
    hasError: passInputInvalid,
    valueChangeHandler: passHandler,
    inputBlurHandler: passBlurHandler,
    reset: resetPassValues,
  } = useInput((value) => value.trim() !== "");

  let formIsValid = false;

  if (emailValid && passValid && userNameValid) {
    formIsValid = true;
  }

  const submitFormHandler = async (event) => {
    event.preventDefault();

    if (!emailValid || !passValid || !userNameValid) {
      return;
    }

    console.log("Submitted");
    console.log(usernameEntered, emailEntered, passEntered);
    setLoading(true);

    // Amplify sign up
    try {
      const { user } = await Auth.signUp({
        username: usernameEntered,
        password: passEntered,
        attributes: {
          email: emailEntered, // optional
          // other custom attributes
        },
        autoSignIn: {
          // optional - enables auto sign in after user is confirmed
          enabled: true,
        },
      });

      history.push(`/verify?value=${user.getUsername()}`);
      setLoading(false);
    } catch (error) {
      console.log("error signing up:", error);
      toast(`Error: ${error}`, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
    }

    resetEmailValues();
    resetPassValues();
    resetUserNameValues();
  };

  const submitGoogleSignUp = (event) => {
    event.preventDefault();
    Auth.federatedSignIn({ provider: "Google" });
  };

  const { textColor, bgColor } = useThemeColorsWithBg();

  const style = {
    color: textColor,
    backgroundColor: bgColor,
    zIndex: "200",
  };

  const textColorTwo = useTextColor();

  const styleText = {
    color: textColorTwo,
  };

  return (
    <section
      style={{ ...style, position: "absolute" }}
      className={classes["section-signUp"]}
    >
      <MainLogo></MainLogo>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div style={style} className={classes.signupMain}>
        <div className={classes.advantages}>
          <h3 className={classes.advantages__heading}>10X Your Productivity</h3>
          <ul style={styleText} className={classes.advantages__points}>
            <li className={classes["advantages__points--text"]}>
              - Manage your time between two sessions
            </li>
            <li className={classes["advantages__points--text"]}>
              - Two Advance Timers - Pomodoro & Tromodoro
            </li>
            <li className={classes["advantages__points--text"]}>
              - Listen to your favorite Playlist while Working.
            </li>
            <li className={classes["advantages__points--text"]}>
              - List your Tasks and complete them during during Sessions.
            </li>
          </ul>
        </div>
        <div style={style} className={classes["signUp-page"]}>
          <h1 className={classes["signUp-header"]}>Create an account</h1>
          <h3 className={classes["signUp-logIn"]}>
            Already have an account?
            <a href="/login" className={classes["signUp-LogInLink"]}>
              {" "}
              Log In{" "}
            </a>
          </h3>
          <h3 style={styleText} className={classes["signUp-secondaryHeader"]}>
            Sign up with
          </h3>
          {loading ? (
            <h3 className={classes.loadingIcon}>
              <Oval
                height={20}
                width={20}
                color="#fff"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#4fa94d"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </h3>
          ) : (
            <div>
              <div className={classes["googleSignIn"]}>
                <button
                  onClick={submitGoogleSignUp}
                  className={classes["googleSignIn__btn"]}
                >
                  <img
                    src={GoogleIcon}
                    alt="Google Sign up Icon"
                    className={classes.googleIcon}
                  ></img>
                  <div className={classes["googleSignIn__btn--text"]}>
                    Google
                  </div>
                </button>
              </div>
              <div className={classes["or"]}>Or</div>
              <form
                onSubmit={submitFormHandler}
                className={classes["signUp-form"]}
              >
                <div className={classes["control-group"]}>
                  {/* <label htmlFor="username">Your Name</label> */}
                  <input
                    onChange={userNameHandler}
                    onBlur={userNameBlurHandler}
                    value={usernameEntered}
                    placeholder="Your Name*"
                    type="username"
                    id="username"
                  ></input>
                  {userNameInvalid && (
                    <p className={classes["error-text"]}>
                      Entered Username Not Valid!
                    </p>
                  )}
                </div>
                <div className={classes["control-group"]}>
                  {/* <label htmlFor="email">Email address</label> */}
                  <input
                    onChange={emailHandler}
                    onBlur={emailBlurHandler}
                    value={emailEntered}
                    placeholder="Email address*"
                    type="email"
                    id="email"
                  ></input>
                  {emailInputInvalid && (
                    <p className={classes["error-text"]}>
                      Entered Email Not Valid!
                    </p>
                  )}
                </div>
                <div className={classes["control-group"]}>
                  {/* <label htmlFor="password"> Password</label> */}
                  <input
                    onChange={passHandler}
                    onBlur={passBlurHandler}
                    value={passEntered}
                    placeholder="Password*"
                    type="password"
                    id="password"
                  ></input>
                  {passInputInvalid && (
                    <p className={classes["error-text"]}>
                      Entered Password Not Valid!
                    </p>
                  )}
                </div>
                <button
                  disabled={!formIsValid}
                  className={classes["sign-in-btn"]}
                  type="submit"
                >
                  <div className={classes.btnText}>Sign up</div>
                  <span>
                    <img
                      src={rightArrow}
                      className={classes.rightArrow}
                      alt="right arrow"
                    ></img>
                  </span>
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Signup;
