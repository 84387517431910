import { useState, useEffect } from "react";

function useTextColor() {
  const [textColor, setTextColor] = useState("#fff");

  useEffect(() => {
    const currentTheme = localStorage.getItem("theme");
    if (currentTheme === "light") {
      setTextColor("#142747");
    } else {
      setTextColor("#fff");
    }
  }, []);

  return textColor;
}

export default useTextColor;
