import classes from "./Info.module.scss";
import Modal from "../../UI/Modal";
import YouTube from "react-youtube";

const Info = (props) => {
  const videoId = "pYpfHdeUYII";
  return (
    <Modal className={classes.infoModal} hideCartFunction={props.onHideCart}>
      <h3 className={classes.info__heading}>Tromodoro</h3>
      <h3 className={classes.info__secondaryHeading}>
        Watch Our launch video:
      </h3>

      <h3 className={classes.info__description}>
        <div className={classes.info__launchVideo}>
          <iframe
            src="https://www.youtube.com/embed/pYpfHdeUYII"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        It's the next version of Pomodoro. Here, Instead of taking a break every
        45 minutes, and then coming back to work, we have 15 minutes of another
        session of work to complete our small tasks like checking out emails,
        replying to them or doing any small task, so that you dont have to
        create another big session for this important yet boring tasks. Then you
        take a break of 10 mins and come back to work after that's completed
        too!
      </h3>
    </Modal>
  );
};

export default Info;
