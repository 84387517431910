import classes from "./HeaderHomepage.module.scss";
import logoImg from "./../images/tromodoro-logo.png";
import logoImgWhite from "./../images/tromodoro-logo-white.png";
import analyticsWhite from "./../images/analyticsWhite.png";
import analyticsDark from "./../images/analyticsWhite.png";
import userLogo from "./../images/profile-black.png";
import userLogoWhite from "./../images/profile-white.png";
import settingsIcon from "./../images/settings-black.png";
import settingsIconWhite from "./../images/settings-white.png";

import { Link } from "react-router-dom";
import Status from "../auth/Status";
import AccountInfo from "./AccountInfo";
import { useState, useEffect } from "react";
import Button from "../UI/Button";
import useTextColor from "../hooks/use-theme-text";

function HeaderHomepage(props) {
  const textColor = useTextColor();

  const style = {
    color: textColor,
  };

  return (
    <header className={classes.header}>
      <nav className={classes.navigation}>
        <div className={classes["main-logo"]}>
          <Link to="/" style={style}>
            <img
              src={
                localStorage.getItem("theme") === "light"
                  ? logoImg
                  : logoImgWhite
              }
              alt="Swapnil Mane Logo"
              className={classes["main-logo__img"]}
            />
          </Link>
        </div>
        <ul className={classes["navigation__list"]}>
          <li className={classes["navigation__item"]}>
            <div className={classes["navigation__link"]}>
              <Link
                to="/app"
                className={classes["navigation__link--text"]}
                style={style}
              >
                Open App
              </Link>
            </div>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default HeaderHomepage;
