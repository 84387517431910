import classes from "./ProVersion.module.scss";
import Modal from "../UI/Modal";
import useThemeColorsWithBg from "../hooks/use-theme";
import lockImg from "./../images/lock.png";
import CloseIcon from "./../images/cross.png";
import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";

const ProVersion = (props) => {
  const [email, setEmail] = useState(null);
  const history = useHistory();
  // Get Email and username
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        let { username, attributes } = user;
        let email = attributes?.email;

        if (email?.length === undefined) {
          email = user.getSignInUserSession().getIdToken().payload.email;
        }
        setEmail(email);
      })
      .catch((error) => console.log(error));
  }, []);

  const upgradeProHandler = () => {
    if (email) {
      history.push("/upgrade");
    } else {
      history.push("/signup");
    }

    props.onHideProVersionModal();
  };

  return (
    <Modal className={classes.proVersion}>
      <img
        alt="Close icon"
        src={CloseIcon}
        className={classes.close_proVersionModal}
        onClick={props.onHideProVersionModal}
      ></img>
      <h2 className={classes.proVersion__title}>
        {email ? "Feature Locked" : "Login Required"}
      </h2>
      <h3 className={classes.proVersion__SecondaryTitle}>
        {email
          ? "Upgrade to pro to Unlock extra features"
          : "Please Login to access this feature"}
      </h3>
      <img src={lockImg} alt="Locked Icon" className={classes.lockImg}></img>
      <button onClick={upgradeProHandler} className={classes.upgradeBtn}>
        {email ? "Upgrade" : "Sign up / Login"}
      </button>
    </Modal>
  );
};

export default ProVersion;
