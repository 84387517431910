// worker.js
const workercode = () => {

    onmessage = function(event) {
        let isRunning = event.data.isRunning;
        let seconds = event.data.seconds;
        let intervalId = null;
        
        if(isRunning){
            intervalId = setInterval(() => {
                seconds -= 1;
                postMessage({seconds});
            }, 1000)
        } else{
            clearInterval(intervalId);
        }
    }
};

let code = workercode.toString();
code = code.substring(code.indexOf("{")+1, code.lastIndexOf("}"));

const blob = new Blob([code], {type: "application/javascript"});
const worker_script = URL.createObjectURL(blob);

module.exports = worker_script;