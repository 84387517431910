import classes from "./IndividualTasks.module.scss";
import CrossLogo from "./../../images/tasks-cross.png";
import TaskOptionsHover from "./TaskOptionsHover";
import { useState } from "react";

const IndividualTasks = (props) => {
  const [taskOptionsVisible, setTaskOptionsVisible] = useState(false);

  const taskOptionsHandler = () => {
    setTaskOptionsVisible(!taskOptionsVisible);
  };

  const removeTaskHandler = (event) => {
    props.taskRemoval(props.taskIndex);
    setTaskOptionsVisible(false);
  };

  const resetTaskHandler = (event) => {
    props.taskReseter(props.taskIndex);
    setTaskOptionsVisible(false);
  };

  let statusContent = null;
  if (props.status === "Completed") {
    statusContent = (
      <div>
        <div className={classes["tasks__details--nums"]}>
          {props.taskCurrNums}/{props.taskNums}
        </div>
        <div className={classes["tasks__details--timer"]}>
          {props.taskType === "Tromodoro"
            ? props.currTimer === "tromo"
              ? "Tromo"
              : "Pomo"
            : "Short-W"}
        </div>
        <div
          className={`${classes["tasks__details--status"]} ${classes.green}`}
        >
          {props.status}
        </div>
      </div>
    );
  } else if (props.status === "In Progress") {
    statusContent = (
      <div>
        <div className={classes["tasks__details--nums"]}>
          {props.taskCurrNums}/{props.taskNums}
        </div>
        <div className={classes["tasks__details--timer"]}>
          {props.taskType === "Tromodoro"
            ? props.currTimer === "tromo"
              ? "Tromo"
              : "Pomo"
            : "Short-W"}
        </div>
        <div
          className={`${classes["tasks__details--status"]} ${classes.orange}`}
        >
          {props.status}
        </div>
      </div>
    );
  } else if (props.status === "On Way") {
    statusContent = (
      <div>
        <div className={classes["tasks__details--nums"]}>
          {props.taskCurrNums}/{props.taskNums}
        </div>
        <div className={classes["tasks__details--timer"]}>
          {props.taskType === "Tromodoro"
            ? props.currTimer === "tromo"
              ? "Tromo"
              : "Pomo"
            : "Short-W"}
        </div>
        <div className={`${classes["tasks__details--status"]} ${classes.red}`}>
          {props.status}
        </div>
      </div>
    );
  } else if (props.status === "Not Completed") {
    statusContent = (
      <div>
        <div className={classes["tasks__details--nums"]}>
          {props.taskCurrNums}/{props.taskNums}
        </div>
        <div className={classes["tasks__details--timer"]}>
          {props.taskType === "Tromodoro"
            ? props.currTimer === "tromo"
              ? "Tromo"
              : "Pomo"
            : "Short-W"}
        </div>
        <div className={`${classes["tasks__details--status"]} ${classes.red}`}>
          {props.status}
        </div>
      </div>
    );
  }

  return (
    <div
      className={
        props.selected
          ? `${classes.tasks__details} ${classes.selected}`
          : classes.tasks__details
      }
    >
      <div className={classes["tasks__details--title"]}>{props.title}</div>
      {statusContent}
      <div className={classes["taskOptions"]}>
        {taskOptionsVisible && (
          <TaskOptionsHover
            resetTask={resetTaskHandler}
            deleteTask={removeTaskHandler}
          ></TaskOptionsHover>
        )}

        <img
          src={CrossLogo}
          alt="Delete Tasks"
          onClick={taskOptionsHandler}
          className={classes["tasks__details--cross"]}
        ></img>
      </div>
    </div>
  );
};

export default IndividualTasks;
