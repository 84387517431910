import React, { createContext } from "react";
import UserPool from "../auth/UserPool";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";

const AccountContext = createContext();

const Account = (props) => {
  const history = useHistory();

  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      // const user = UserPool.getCurrentUser();

      Auth.currentAuthenticatedUser()
        .then((user) => {
          if (user) {
            user.getSession((err, session) => {
              if (err) {
                reject(err);
              } else {
                resolve(session);
              }
            });
          } else {
            reject();
          }
        })
        .catch(() => {});
    });
  };

  const authenticate = async (emailEntered, passEntered) => {
    // Sign in with Amplify
    try {
      const user = await Auth.signIn(emailEntered, passEntered);
      history.push("/app");
      window.location.reload();

      return user;
    } catch (error) {
      console.log("error signing in", error);
      return `Error: ${error}`;
    }
  };

  const handleAutoSignIn = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      history.push("/app");
      window.location.reload();
      return "User authenticated";
    } catch (error) {
      console.log("error signing in", error);
      history.push("/app");
      return `Error: ${error}`;
    }
  };

  const logout = () => {
    try {
      // const user = UserPool.getCurrentUser();
      // console.log(user);
      // if (user) {
      //   user.signOut();
      //   window.location.reload();
      // }

      Auth.currentAuthenticatedUser()
        .then((user) => {
          user.signOut();
          window.location.reload();
        })
        .catch(() => {});
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AccountContext.Provider
      value={{ authenticate, getSession, handleAutoSignIn, logout }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };
