import classes from "./TimeConfigure.module.scss";

const TimeConfigure = (props) => {
  const workChangeHandler = (event) => {
    props.dataGetter({ type: "work", time: +event.target.value });
  };

  const shortWorkChangeHandler = (event) => {
    props.dataGetter({ type: "short-work", time: +event.target.value });
  };

  const breakChangeHandler = (event) => {
    props.dataGetter({ type: "break", time: +event.target.value });
  };

  return (
    <div className={classes.settings__category}>
      <h3 className={classes.category__heading}>Time (minutes)</h3>
      <div className={classes.category__inputs}>
        <div className={classes.timer}>
          <div className={classes.timer__heading}>Tromodoro</div>
          <input
            type="number"
            defaultValue={props.tromodoroTimings.work}
            onChange={workChangeHandler}
            min="1"
            max="120"
            className={classes.timer__value}
          ></input>
        </div>
        <div className={classes.timer}>
          <div className={classes.timer__heading}>Short Work</div>
          <input
            type="number"
            defaultValue={props.tromodoroTimings.shortWork}
            onChange={shortWorkChangeHandler}
            min="1"
            max="120"
            className={classes.timer__value}
          ></input>
        </div>
        <div className={classes.timer}>
          <div className={classes.timer__heading}>Break</div>
          <input
            type="number"
            defaultValue={props.tromodoroTimings.break}
            onChange={breakChangeHandler}
            min="1"
            max="120"
            className={classes.timer__value}
          ></input>
        </div>
      </div>
    </div>
  );
};

export default TimeConfigure;
