// Testing API's
// export const SpotifyLoginURL =
//   "https://bha8eeln13.execute-api.us-east-1.amazonaws.com/Testing_API/spotify-auth";
// export const SpotifyGetAccessAndRefreshToken =
//   "https://bha8eeln13.execute-api.us-east-1.amazonaws.com/Testing_API/-spotify-access-token";
// export const SpotifyRefreshAccessToken =
//   "https://bha8eeln13.execute-api.us-east-1.amazonaws.com/Testing_API/spotify-refresh-token";

// export const BlockExtensionId = "egaofeafjldfbfcjekajpjnhdbdcbffh";

// export const STRIPE_PUBLISHABLE_KEY =
//   "pk_test_51JX11gSJ5YQ6rL5B67HrFanqbJ4LnitLPFB9MyLVBnFEkQ4E3CTh4ZZGVy689bKqt7v2o9qsjxW8ptbaJugGYXmx00MnjwC5Bw";

// export const createAPIUser =
//   "https://g4leymbgbwkiyjrtbvsbd5sopq0nwiix.lambda-url.us-east-1.on.aws/";

// Deployment API's
export const SpotifyLoginURL =
  "https://snjy271pdj.execute-api.us-east-1.amazonaws.com/dev/spotify-auth";
export const SpotifyGetAccessAndRefreshToken =
  "https://snjy271pdj.execute-api.us-east-1.amazonaws.com/dev/-spotify-access-token";
export const SpotifyRefreshAccessToken =
  "https://snjy271pdj.execute-api.us-east-1.amazonaws.com/dev/spotify-refresh-token";
export const BlockExtensionId = "eclbdogfkckieoffjdjfbillhljihdmm";

export const createAPIUser =
  "https://g4leymbgbwkiyjrtbvsbd5sopq0nwiix.lambda-url.us-east-1.on.aws/";

export const STRIPE_PUBLISHABLE_KEY =
  "pk_live_51JX11gSJ5YQ6rL5BqyAOKyr32JJv7btSafYaK3mzVrRMg0affQBAMd6zZlLNJATGii5AS5VpVVCgrTTZxqtGLAfy00relHR1w8";
