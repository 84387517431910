import classes from "./MainPlayerWrapper.module.scss";
import { useState, useEffect } from "react";
import { SpotifyRefreshAccessToken } from "../../topVars";
import PrevTrackLogo from "./../../images/prev-track.png";
import NextTrackLogo from "./../../images/next-track.png";
import spotifyLogo from "./../../images/Spotify_Icon_RGB_Black.png";

const track = {
  name: "",
  album: {
    images: [{ url: "" }],
  },
  artists: [{ name: "" }],
};

function truncateString(str) {
  if (str) {
    if (str.length > 25) {
      return str.slice(0, 25) + "...";
    }
  }

  return str;
}

const MainPlayerWrapper = (props) => {
  const [current_track, setTrack] = useState(track);
  const [player, setPlayer] = useState(undefined);
  //   For Playback
  const [is_paused, setPaused] = useState(true);
  const [is_active, setActive] = useState(false);
  const [isPlayerReady, setIsPlayerReady] = useState(false);

  // useEffect(() => {
  //   props.trackGotChanged(current_track);
  // }, [current_track]);

  // Refresh access Token
  const refreshAccessToken = async () => {
    try {
      const response = await fetch(
        `${SpotifyRefreshAccessToken}?refresh_token=${localStorage.getItem(
          "refreshToken"
        )}`
      );
      const data = await response.json();
      const currAccessToken = data.accessToken;

      if (
        typeof currAccessToken !== "object" &&
        typeof refreshToken !== "object"
      ) {
        // Storing the accessToken in Local storage
        localStorage.removeItem("accessToken");
        localStorage.setItem("accessToken", currAccessToken);
        props.setNewAccess(currAccessToken);
      }

      return currAccessToken;
    } catch (error) {
      // console.error(error);
    }
  };

  useEffect(() => {
    refreshAccessToken();
    try {
      if (localStorage.getItem("accessToken")) {
        const script = document.createElement("script");
        script.src = "https://sdk.scdn.co/spotify-player.js";
        script.async = true;

        document.body.appendChild(script);

        window.onSpotifyWebPlaybackSDKReady = () => {
          const player = new window.Spotify.Player({
            name: "Tromo Player",
            getOAuthToken: (cb) => {
              // const newAccessToken = refreshAccessToken();
              // cb(newAccessToken ? newAccessToken : props.token);
              cb(localStorage.getItem("accessToken"));
            },
            volume: 0.8,
          });

          setPlayer(player);

          player.addListener("ready", ({ device_id }) => {
            // console.log("Ready with Device ID", device_id);
            // Here is the device Id, pass it to Parent element and then SPotify Player
            console.log("Here is the device ID");
            console.log(device_id);

            props.getDeviceId(device_id);

            setIsPlayerReady(true);
            props.settingPlayerReady(true);
          });

          player.addListener("not_ready", ({ device_id }) => {
            // console.log("Device ID has gone offline", device_id);
          });

          player.addListener("autoplay_failed", () => {
            // console.log(
            //   "Autoplay is not allowed by the browser autoplay rules"
            // );
          });

          //   New addition
          player.addListener("player_state_changed", (state) => {
            if (!state) {
              return;
            }

            setTrack(state.track_window.current_track);
            setPaused(state.paused);

            player.getCurrentState().then((state) => {
              !state ? setActive(false) : setActive(true);
            });
          });

          player.connect().then((success) => {
            if (success) {
              console.log(
                "The Web Playback SDK successfully connected to Spotify!"
              );
            }
          });
        };
      }
    } catch (err) {
      // console.log("Trying to start the player");
    }
  }, []);

  if (
    props.isPlayerPlaying &&
    props.playSongsWhileTimer &&
    !props.isInbuiltPlaylistSelected
  ) {
    if (player) {
      player.resume().then(() => {
        // console.log("Resumed");
      });
      //   setSongLoaded(true);
    }
  } else if (
    props.isPlayerPlaying === false ||
    props.playSongsWhileTimer === false ||
    props.isInbuiltPlaylistSelected === true
  ) {
    if (player) {
      player.pause().then(() => {
        // console.log("Paused");
      });
      // setSongLoaded(false);
    }
  } else {
    player.pause();
  }

  // let songNameAndArtist = `${current_track.name} - ${current_track.artists[0].name}`;
  // songNameAndArtist = truncateString(songNameAndArtist);

  let songTitle = current_track?.name;
  let artistName = current_track?.artists[0]?.name;
  let albumName = current_track?.album?.name;

  try {
    songTitle = truncateString(songTitle);
    artistName = truncateString(artistName);
    albumName = truncateString(albumName);
  } catch (error) {
    console.log(error);
  }

  return (
    <div>
      {current_track?.album?.images[0]?.url ? (
        <div className={classes["main-wrapper"]}>
          <img
            alt="Spotify Logo"
            src={spotifyLogo}
            className={classes.spotifyAttribution}
          ></img>
          <div className={classes["CoverInfoBox"]}>
            <img
              src={current_track?.album?.images[0]?.url}
              className={classes["now-playing__cover"]}
              alt=""
            />
            <div className={classes.playingDetails}>
              <div className={classes["now-playing__side"]}>
                <div className={classes["now-playing__artist"]}>
                  {songTitle}
                </div>
              </div>
              <div className={classes["now-playing__side"]}>
                <div className={classes["now-playing__artist"]}>
                  {artistName}
                </div>
              </div>

              <div className={classes["now-playing__side"]}>
                <div className={classes["now-playing__artist"]}>
                  {albumName}
                </div>
              </div>
            </div>
          </div>

          <div>
            <button
              className={classes["btn-spotify"]}
              onClick={() => {
                player.previousTrack();
              }}
            >
              <img
                src={PrevTrackLogo}
                alt="Previous Track Btn"
                className={classes.TrackPlayback}
              ></img>
            </button>
            <button
              className={classes["btn-spotify"]}
              onClick={() => {
                player.nextTrack();
              }}
            >
              <img
                src={NextTrackLogo}
                alt="Next Track Btn"
                className={classes.TrackPlayback}
              ></img>
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default MainPlayerWrapper;
