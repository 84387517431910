import classes from "./HomepageThirdSection.module.scss";
import pomoTomato from "./../../images/pomoTomato.png";
import tromoTomato from "./../../images/tromoTomato.png";

const HomepageThirdSection = (props) => {
  return (
    <section className={classes.sectionInfo}>
      <div className={`${classes.timerInfo} ${classes.timerInfo__pomo}`}>
        <h2
          className={`${classes.timerInfo__heading} ${classes.timerInfo__pomoTimerHeading}`}
        >
          Pomodoro Timer
        </h2>
        <h2 className={classes.timerInfo__secondaryHeading}>
          Productivity Technique introduced by Francesco Cirrllo to help you
          manage time
        </h2>
        <div className={classes.timerDetails}>
          <div className={classes.timerDetails__imageDiv}>
            <img
              alt="Pomodoro Tomato"
              src={pomoTomato}
              className={classes.timerDetails__img}
            ></img>
          </div>
          <ul className={classes.timerDetails__stepList}>
            <li className={classes.timerDetails__stepList__text}>
              1.Start the timer of 25 mins or more for a particular Task
            </li>
            <li className={classes.timerDetails__stepList__text}>
              2.Take a break after the time is over
            </li>
            <li className={classes.timerDetails__stepList__text}>
              3.Congrats, One Pomodoro is completed!
            </li>
          </ul>
        </div>
        <div className={`${classes.timerFinalStep} ${classes.pomoFinalStep}`}>
          Now do 4 Pomodoro sessions and take a long break!
        </div>
      </div>
      <div className={`${classes.timerInfo} ${classes.timerInfo__tromo}`}>
        <h2
          className={`${classes.timerInfo__heading} ${classes.timerInfo__tromoTimerHeading}`}
        >
          Tromodoro Timer
        </h2>
        <h2 className={classes.timerInfo__secondaryHeading}>
          Productivity Technique inspired by Pomodoro Timer, but helps you to
          manage shorts tasks as well
        </h2>
        <div className={classes.timerDetails}>
          <div className={classes.timerDetails__imageDiv}>
            <img
              alt="Tromodoro Tomato"
              src={tromoTomato}
              className={classes.timerDetails__img}
            ></img>
          </div>
          <ul className={classes.timerDetails__stepList}>
            <li className={classes.timerDetails__stepList__text}>
              1.Start the timer of 45 mins or more for a long task
            </li>
            <li className={classes.timerDetails__stepList__text}>
              2.Start the timer of 15 mins (or less) for short work like
              Replying to your emails, coming to social media, scheduling a
              meeting or more
            </li>
            <li className={classes.timerDetails__stepList__text}>
              3.Take a break after the Short work is over
            </li>
            <li className={classes.timerDetails__stepList__text}>
              4.Congrats, One Tromodoro is completed!
            </li>
          </ul>
        </div>

        <div className={`${classes.timerFinalStep} ${classes.tromoFinalStep}`}>
          Now do 3 Tromodoro sessions and take a long break!
        </div>
      </div>
    </section>
  );
};

export default HomepageThirdSection;
