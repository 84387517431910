import classes from "./BlockerModal.module.scss";
import Modal from "../../UI/Modal";
import crossIcon from "./../../images/settings.png";

const BlockerModal = (props) => {
  return (
    <Modal
      hideCartFunction={props.onHideBlockerModal}
      className={classes.blocker}
    >
      <div className={classes.blocker__info}>
        <h2 className={classes.blocker__title}>Tromo Website Blocker</h2>
        <img
          alt="Exit icon"
          src={crossIcon}
          className={classes.exitBlockerIcon}
          onClick={props.onHideBlockerModal}
        ></img>
      </div>
      <div className={classes.blocker__content}>
        <p className={classes.blocker__text}>
          Download Tromo Website Blocker to block distracting websites while
          working. It automatically connects with Tromodoro.
        </p>

        <div className={classes.supportedBrowsers}>
          <h2 className={classes.supportedBrowsersText}>Supported Browsers:</h2>
          <ul className={classes.supportedBrowsersList}>
            <li className={classes.supportedBrowsersListItem}>
              Chrome:
              <a
                href="https://chrome.google.com/webstore/detail/tromo-website-blocker/eclbdogfkckieoffjdjfbillhljihdmm"
                className={classes.browserLink}
                target="_blank"
                rel="noreferrer"
              >
                Install
              </a>
            </li>
            <li className={classes.supportedBrowsersListItem}>
              Edge:&nbsp;&nbsp;&nbsp;&nbsp;
              <a
                target="_blank"
                rel="noreferrer"
                href="https://microsoftedge.microsoft.com/addons/detail/tromo-website-blocker/jejfkkjajfpmjbmoamamhldnihllbepd"
                className={classes.browserLink}
              >
                Install
              </a>
            </li>
            <li className={classes.supportedBrowsersListItem}>
              Brave:&nbsp;&nbsp;
              <a
                target="_blank"
                rel="noreferrer"
                href="https://chrome.google.com/webstore/detail/tromo-website-blocker/eclbdogfkckieoffjdjfbillhljihdmm"
                className={classes.browserLink}
              >
                Install
              </a>
            </li>
          </ul>
          <p className={classes.end__text}>
            Close after installing the extension on each browser and we will do
            the rest 😁
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default BlockerModal;
