import classes from "./MainLogo.module.scss";
import logoImg from "./../images/logo-black.png";
import logoImgWhite from "./../images/tromodoro-logo-white.png";
// import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

const MainLogo = (props) => {
  // const history = useHistory();

  // const LinkHandler = () => {
  //   history.push("/");
  //   window.location.reload(false);
  // };

  return (
    <a href="/" className={classes["main-logo"]}>
      <img
        src={localStorage.getItem("theme") === "light" ? logoImg : logoImgWhite}
        // onClick={LinkHandler}
        alt="Swapnil Mane Logo"
        className={classes["main-logo__img"]}
      />
    </a>
  );
};

export default MainLogo;
