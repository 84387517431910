import classes from "./HomepageSecondSection.module.scss";
import tromoImageInfo from "./../../images/tromoImageInfo.png";
import BuyButton from "../../UI/BuyButton";

const HomepageSecondSection = (props) => {
  return (
    <section className={classes.sectionProductivity}>
      <h1 className={classes.primaryHeading}>
        10X Your <span className={classes.prodText}>Productivity</span>
      </h1>
      <h3 className={classes.secondaryHeading}>
        Tromodoro - The Advanced Pomodoro Timer
      </h3>

      <div className={classes.moreFeatures}>
        <img
          alt="Tromodoro Information"
          src={tromoImageInfo}
          className={classes.tromoImgInfo}
        ></img>

        {/* <div className={classes.features}>
          <div className={classes.moreFeatures__text}>
            And some more features:
          </div>
          <ul className={classes.featuresList}>
            <li className={classes.featuresList__text}>- Powerful Analytics</li>
            <li className={classes.featuresList__text}>- Inbuilt Playlist</li>
            <li className={classes.featuresList__text}>
              - Notifications when the timer ends
            </li>
            <li className={classes.featuresList__text}>- and many more...</li>
          </ul>
        </div> */}
      </div>
      <div className={classes.openAppComponent}>
        <BuyButton></BuyButton>
      </div>
    </section>
  );
};

export default HomepageSecondSection;
