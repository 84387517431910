import React from "react";
import classes from "./BuyButton.module.scss";
import { Link } from "react-router-dom";

const BuyButton = (props) => {
  return (
    <Link to="/app" className={classes.BuyButton}>
      Open App
    </Link>
  );
};

export default BuyButton;
